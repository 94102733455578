import React, { useContext, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { useHistory } from 'react-router';
import { handleUpload } from '../firebase';
import { IonPage } from '@ionic/react';
import { ReportContextCreation } from '../App';
import DefaultWrapper from '../components/defaultWrapper';

import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

export default function Canvas(props) {
    const { t } = useTranslation();
    const [lines, setLines] = useState([]);
    const [strokeWidth, setStrokeWidth] = useState(10);
    const [image, setImage] = useState(null);

    const stageRef = useRef();

    const history = useHistory();

    const disableDefaultTouchBehaviour = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (!props.reset) return;
        setImage(null);
        setLines([]);
    }, [props.reset]);

    useEffect(() => {
        // Event listeners to disable default touch behaviour on the canvas
        const stageElement = stageRef.current;
        stageElement.addEventListener('touchmove', disableDefaultTouchBehaviour, { passive: false });

        // Cleanup function
        return () => {
            stageElement.removeEventListener('touchmove', disableDefaultTouchBehaviour);
        };
    }, []);

    const handleMouseDown = (e) => {
        //e.preventDefault();
        const stage = stageRef.current;
        const pos = stage.getPointerPosition();
        setLines([...lines, { points: [pos.x, pos.y], strokeWidth }]);
    };

    const handleMouseMove = (e) => {
        //e.preventDefault();
        e.evt.preventDefault();

        const isTouch = e.evt.type === 'touchmove';
        if ((!isTouch && (!e.evt.buttons)) || (isTouch && !e.evt.touches)) return;

        //const relativePos = stageRef.current.getPointerPosition();
        const stage = stageRef.current;
        const pos = /*isTouch ? {
            x: e.evt.touches[0].clientX -20,
            y: e.evt.touches[0].clientY + -20 + (window.innerHeight / 2) - (window.innerHeight / 0.20)
        } :*/ stage.getPointerPosition();

        const lastLine = lines[lines.length - 1];
        const newPoints = lastLine.points.concat([pos.x, pos.y]);
        const newLines = lines.slice(0, lines.length - 1);
        setLines([...newLines, { points: newPoints, strokeWidth }]);
    };

    // send the image to firebase, then navigate to pdfPreview with the image and the report
    const onCapture = async (e) => {
        
        const dataUrl = stageRef.current.toDataURL({ pixelRatio: 1 });
        console.log(dataUrl);

        const blob = await fetch(dataUrl).then((r) => r.blob());
        const file = new File([blob], 'image.png', { type: 'image/png' });
        console.log(file);

        const imageLink = await handleUpload(file, "signaturer", randomId());
        console.log(imageLink);

        // navigate to pdfPreview with signature added to the state information
        //let report = history.location.state;

        // set context signatur to imageLink
        //report.signatur = imageLink;
        setImage(imageLink);
        if (props.onExport) props.onExport(imageLink);
    };

    return (
        <div className="column center">
            <div className={image ? "img-holder canvas-container" : "canvas-container"}>
                <div className="canvas shadow column" style={{
                    height: image ? "200px" : window.innerHeight / 2,
                    transition: "all 0.5s ease-in-out",
                }}>
                    {image ? <img src={image} alt="signatur" style={{ height: 200 }} /> :
                        <Stage
                            ref={stageRef}
                            width={window.innerWidth - 40}
                            height={window.innerHeight / 2}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}

                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}

                        //onPointerDown={handleMouseDown}
                        //onPointerMove={handleMouseMove}
                        //onPointerUp={handleMouseUp}
                        >
                            <Layer>
                                {lines.map((line, i) => (
                                    <Line
                                        key={i}
                                        points={line.points}
                                        stroke="black"
                                        strokeWidth={Number(line.strokeWidth)}
                                        lineCap="round"
                                        lineJoin="round"
                                    />
                                ))}
                            </Layer>
                        </Stage>
                    }
                </div>

                <div className="bottomLine"></div>
            </div>

            {image ? <button type={"button"} className="button" onClick={() => setImage(null)}>{t("Retry", "Skriv på nytt")}</button> :
                <button type={"button"} className="button" onClick={onCapture}>{t("Done", "Ferdig")}</button>
            }
        </div>
    );
};


function randomId() {
    return Math.random().toString(36).substr(2, 9);
}