import { checkIfRCTimeCompleted } from "../timeSlice";

const rehydrateRcTimeMiddleware = (store) => (next) => (action) => {
    if (action.type === 'persist/REHYDRATE') {
        const rehydratedState = action.payload;
        console.log(rehydratedState);
        const rcTime = rehydratedState?.rcTime;
        if (rcTime) {
            if (rcTime.startDate) {
                // Dispatch the async thunk to check if rcTime is completed
                store.dispatch(checkIfRCTimeCompleted(rcTime));
            }
        }
    }

    return next(action);
};

export default rehydrateRcTimeMiddleware;
