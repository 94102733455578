/*
For Lazt loading do:
import (\w+) from '(.*)';
const $1 = lazy(() => import('$2'));
*/

import { Redirect, Route, Switch, } from 'react-router-dom';
import {
  IonRouterOutlet,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Tab1 from './pages/Tab1';

import Tab3 from './pages/login/Tab3';

// Pages
const ClientList = lazy(() => import('./pages/Klienter/clientList'));
const CreateClient = lazy(() => import('./pages/Klienter/createClient'));
const Rapporter = lazy(() => import('./pages/reports/rapporter'));
const Rapport = lazy(() => import('./pages/reports/rapport'));
const PdfPreview = lazy(() => import('./components/exportToPdf'));
const PDFViewer = lazy(() => import('./pages/reports/pdfViewer'));
const SenteRapporter = lazy(() => import('./pages/Klienter/projekter/senteRapporter'));
const Canvas = lazy(() => import('./pages/reports/canvas'));
const UtstyrsListe = lazy(() => import('./pages/utstyrsliste'));
const ManageUtstyr = lazy(() => import('./pages/manageUtstyr'));
const UtstyrsDetaljer = lazy(() => import('./pages/utstyrsdetaljer'));

import { LoginPage } from './pages/login/loginPage';
import { lazy, Suspense, useState } from 'react';
const Kalkulator = lazy(() => import('./pages/kalkulator'));
const NoteTakingPage = lazy(() => import('./pages/notater'));

const AdministratorPage = lazy(() => import('./pages/administrator'));
const Brukere = lazy(() => import('./pages/Ansatte/brukere'));
const BrukerInstillinger = lazy(() => import('./pages/Ansatte/brukerInstillinger'));
const ChooseCompany = lazy(() => import('./pages/chooseCompany'));
const ReportSettings = lazy(() => import('./pages/reports/mal/rapport-maler'));
const NyMal = lazy(() => import('./pages/reports/mal/nyMal'));
const ReportInforing2 = lazy(() => import('./components/ReportInforing'));
import CreateCompanyPage, { BrukerVilkår } from './pages/login/createCompany';
import DefaultWrapper from './components/defaultWrapper';
const RolleConfig = lazy(() => import('./pages/rolleConfig'));
const Roller = lazy(() => import('./pages/roller'));
const TimeTrackerMenu = lazy(() => import('./pages/timeføring/timetrackerMenu'));
const NotatLobby = lazy(() => import('./pages/notatLobby'));
const EditCompanyPage = lazy(() => import('./pages/login/editCompany'));

//import { FCM } from "@capacitor-community/fcm";
//import { PushNotifications } from "@capacitor/push-notifications";

const Invitation = lazy(() => import('./pages/Ansatte/invitasjoner'));
const TimeSettings = lazy(() => import('./pages/timeSettings'));
const TimeAdminPage = lazy(() => import('./pages/timeføring/admin/timeAdmin'));
const NyTid = lazy(() => import('./pages/timeføring/admin/newTime'));
const ProjektOverview = lazy(() => import('./pages/Klienter/projekter/projektOverview'));
const ProjektDokumenter = lazy(() => import('./pages/Klienter/projekter/projektDokumenter'));
const RegisterUser = lazy(() => import('./pages/login/register'));
const HMSMeny = lazy(() => import('./pages/HMS/HMSMeny'));
const RUHSkjema = lazy(() => import('./pages/HMS/RUH'));
import PcHeader from './components/PCHeader';
import Geolokasjon from './pages/settings/geolocation';
const Instillinger = lazy(() => import('./pages/Instillinger'));
const PermissionHandler = lazy(() => import('./pages/settings/generalSettings'));
import PcHome from './pages/PCHome';
const AdminInstillinger = lazy(() => import('./pages/settings/admin/adminInstillinger'));
const RapportInstillinger = lazy(() => import('./pages/settings/admin/rapportInstillinger'));
import { NewTimePage } from "./pages/timeføring/newTime.jsx";
const Profile = lazy(() => import('./pages/profile'));
const VanligOversiktMenu = lazy(() => import('./pages/timeføring/vanligTimeoversikt'));
import FørteUkeTimer from './pages/timeføring/førteUkeTimer.jsx';
const TimeforingAktivitet = lazy(() => import('./pages/settings/admin/timeføringsInstillinger/aktiviteter'));
const TimeforingInstillinger = lazy(() => import('./pages/settings/admin/timeføringsInstillinger'));
const Timegodkjenning = lazy(() => import('./pages/timeføring/admin/timegodkjenning.jsx'));
const TimegodkjenningUser = lazy(() => import('./pages/timeføring/admin/timegodkjenningUser.jsx'));
const WeekGodkjenning = lazy(() => import('./pages/timeføring/admin/WeekTimegodkjenning.jsx'));
const ProjectList = lazy(() => import('./pages/Klienter/projekter/ProjectList.jsx'));
const Moduler = lazy(() => import('./pages/modules/modules'));
const ArbeidsplanTime = lazy(() => import('./pages/timeføring/arbeidsplanTime.jsx'));
const EditTimeArbeidsplan = lazy(() => import('./pages/timeføring/admin/editTimeTimeplan'));
const AdminTimeOverview = lazy(() => import('./pages/timeføring/admin/adminOversikt'));
const Tillegg = lazy(() => import('./pages/settings/admin/timeføringsInstillinger/tillegg.jsx'));
import { Network } from '@capacitor/network';
const Holiday = lazy(() => import('./pages/Absence/Holiday.jsx'));
const ParentalLeave = lazy(() => import('./pages/Absence/ParentalLeave.jsx'));
const SickLeave = lazy(() => import('./pages/Absence/SickLeave.jsx'));
const SelfDeclaration = lazy(() => import('./pages/Absence/SelfDeclaration.jsx'));
const TimeOff = lazy(() => import('./pages/Absence/TimeOff.jsx'));
import { CheckIfOnPC } from './App';
import SigningWarning from './components/signingWarning.jsx';
const TemplatePreviewPage = lazy(() => import('./pages/reports/mal/templatePreviewPage'));
const Client = lazy(() => import('./pages/Klienter/client'));
//import hasUserId from './components/storageFunctions';

const Avvik = lazy(() => import('./pages/HMS/Avvik.jsx'));
const SJA = lazy(() => import('./pages/HMS/SJA.jsx'));
const Vernerunde = lazy(() => import('./pages/HMS/Vernerunde.jsx'));
const SJAPDF = lazy(() => import('./pages/HMS/pdf/SJApdf'));
const Avvikpdf = lazy(() => import('./pages/HMS/pdf/Avvikpdf'));
const RUHPDF = lazy(() => import('./pages/HMS/pdf/RUHpdf.jsx'));
const HMSOverview = lazy(() => import('./pages/HMS/HMSOverview'));
const NavigationCX = lazy(() => import('./contexts/navigation'));
const ResetPassword = lazy(() => import('./pages/login/resetPassword'));
const HMSViewType = lazy(() => import('./pages/HMS/hmsViewType'));
import { useSelector } from 'react-redux';
import { CircularProgress, ThemeProvider, createTheme } from '@mui/material';
//import ReportList from './pages/reports/report-list';
// lazy load the component
const ReportList = lazy(() => import('./pages/reports/report-list'));
//import ReportList from './pages/reports/report-list';
const Diet = lazy(() => import('./pages/settings/admin/timeføringsInstillinger/diet'));
const AddonsAndTypesPage = lazy(() => import('./pages/settings/admin/timeføringsInstillinger/addonsAndTypes'));
import Background from './components/miniComponents/background';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


export default function PathRouter() {
  const user = useSelector(state => state.user.user)
  const [atLogin, setAtLogin] = useState(false)
  const company = useSelector(state => state.company.company)
  const companyId = useSelector(state => state.company.companyId)


  const hasUserId = () => {
    console.log(user)
    return user
  }

  const HandleRedirect = () => {
    console.log("handleRedirect", window.location.pathname, hasUserId(), companyId)
    if (isPathEqualTo("/invitasjoner")) return <Invitation />
    if (!hasUserId()) return RenderIfEqualToRedirect("/login", LoginPage)
    if (!companyId) return RenderIfEqualToRedirect("/company", ChooseCompany)
    console.log("handleRedirect", window.location.pathname)
    if (isPathEqualTo("/hjem")) return RenderIfEqualToRedirect("/hjem", Tab1)
    if (isPathEqualTo("/")) return RenderIfEqualToRedirect("/", Tab1)
    console.log("handleRedirect", window.location.pathname)
    return RenderIfEqualToRedirect("/hjem", Tab1)
  }
  const isPathEqualTo = (path) => {
    //console.log("path", path, window.location.pathname)
    return window.location.pathname === path
  }

  const RenderIfEqualToRedirect = (desiredPath, Component) => {
    // const currentPath = window.location.pathname
    // console.log("currentPath", currentPath)
    if (!isPathEqualTo(desiredPath)) {
      console.log("redirecting", desiredPath)
      return <Redirect to={desiredPath} />
    }
    return <Component />
  }


  return (
    <IonReactRouter ionRouteDidChange={() => console.log("did")} swipeGesture={true}>
      <PcHeader />
      <SigningWarning />
      <NavigationCX />
      <Background />
      <ThemeProvider theme={darkTheme}>
        <div className='stretch-width stretch-height moz-height' style={{ position: 'relative' }}>
          <Suspense fallback={<div className='column center' style={{ width: "100%", height: "100%" }}>
            <CircularProgress style={{ width: "min(50vw, 50vh)", height: "min(50vw, 50vh)" }} />
          </div>
          }>
            <IonRouterOutlet>
              <Switch>
                {/*<ProtectedRoute path="/" component={Tab1} />*/}
                <Route exact path="/hjem">
                  <HandleRedirect />
                  {/*hasUserId() ? (company?.id ? <Tab1 /> : <Redirect to="/company" />) : <Redirect to="/login" />*/}
                </Route>

                <Route exact path="/admin/instillinger/firma">
                  <EditCompanyPage />
                </Route>

                <Route exact path="/admin/instillinger/">
                  <AdminInstillinger />
                </Route>

                <Route exact path="/admin/instillinger/rapporter">
                  <RapportInstillinger />
                </Route>

                <Route exact path="/admin/instillinger/timeforing">
                  <TimeforingInstillinger />
                </Route>

                <Route exact path="/admin/instillinger/timeforing/tillegg">
                  <AddonsAndTypesPage />
                </Route>

                <Route exact path="/admin/instillinger/timeforing/aktivitet/:id">
                  <TimeforingAktivitet />
                </Route>

                <Route exact path="/admin/instillinger/timeforing/diet">
                  <Diet />
                </Route>

                <Route exact path="/absence">
                  <DefaultWrapper />
                </Route>

                <Route exact path="/absence/holiday">
                  <Holiday />
                </Route>

                <Route exact path="/absence/parental-leave">
                  <ParentalLeave />
                </Route>

                <Route exact path="/absence/time-off">
                  <TimeOff />
                </Route>

                <Route exact path="/absence/sick-leave">
                  <SickLeave />
                </Route>

                <Route exact path="/absence/self-declaration">
                  <SelfDeclaration />
                </Route>

                <Route exact path="/moduler">
                  <Moduler />
                </Route>

                <Route path="/tab3">
                  <Tab3 />
                </Route>

                <Route exact path="/profile">
                  <Profile />
                </Route>

                <Route exact path="/kalkulator">
                  <Kalkulator />
                </Route>

                <Route exact path="/customerList">
                  <ClientList />
                </Route>

                <Route exact path="/manageClient">
                  <CreateClient />
                </Route>

                <Route exact path="/customer/:id">
                  <Client />
                </Route>

                <Route exact path="/prosjekt/edit">
                  <ProjektOverview />
                </Route>

                <Route exact path="/prosjekter">
                  <ProjectList />
                </Route>

                <Route exact path="/prosjekt/documents">
                  <ProjektDokumenter />
                </Route>

                <Route exact path="/prosjekter/:id">
                  <ProjektOverview />
                </Route>

                <Route exact path="/rapporter">
                  <ReportList />
                </Route>

                <Route exact path="/rapport/:id">
                  <Rapport />
                </Route>


                <Route exact path="/rapport/:id/oppgave/:task">
                  <ReportInforing2 />
                </Route>


                <Route exact path="/signering">
                  <Canvas />
                </Route>


                <Route exact path="/signert">
                  <PdfPreview />
                </Route>


                <Route exact path="/reportArchive">
                  <SenteRapporter />
                </Route>


                <Route exact path="/reportPreview">
                  <PdfPreview />
                </Route>


                <Route exact path="/pdfViewer">
                  <PDFViewer />
                </Route>



                <Route exact path="/utstyrsliste">
                  <UtstyrsListe />
                </Route>


                <Route exact path="/utstyr">
                  <DefaultWrapper>
                    <ManageUtstyr />
                  </DefaultWrapper>
                </Route>

                <Route exact path="/utstyr/:id">
                  <UtstyrsDetaljer />
                </Route>

                <Route exact path="/login">
                  <HandleRedirect />
                  {/*hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <Redirect to="/company" />) : <LoginPage />*/}
                </Route>

                <Route exact path="/glemt-passord">
                  <ResetPassword />
                </Route>

                <Route exact path="/registrer">
                  <RegisterUser />
                </Route>
                {/*
              <Route exact path="/chat">
                <Chat messages={[]} setIsInChatComponent={() => { }} />
              </Route>
            */}
                { /*
            <Route exact path="/chat/ny">
              <CreateChatMenu />
            </Route>
            */
                }
                {/*
              <Route exact path="/chats/:id">
                <ChatMessagesPage />
              </Route>
            */}
                {/*
            <Route exact path="/chatLobby">
              <ChatLobby />
            </Route>
            */}

                <Route exact path="/notater">
                  <NotatLobby />
                </Route>

                <Route exact path="/notater/:id">
                  <NoteTakingPage />
                </Route>


                <Route exact path="/admin">
                  <AdministratorPage />
                </Route>

                <Route exact path="/brukere">
                  <Brukere />
                </Route>

                <Route path="/brukere/:brukerId">
                  <BrukerInstillinger />
                </Route>

                <Route exact path="/roller">
                  <Roller />
                </Route>

                <Route exact path="/roller/:id">
                  <RolleConfig />
                </Route>
                <Route exact path={"/switch-company"}>
                  <ChooseCompany />
                </Route>

                <Route exact path="/company">
                  <HandleRedirect />
                  {/*hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <ChooseCompany />) : <Redirect to="/login" />*/}
                  {/*hasUserId() ? <ChooseCompany /> : <Redirect to="/login" />*/}
                </Route>

                <Route exact path="/reportSettings">
                  <ReportSettings />
                </Route>

                <Route path="/reportSettings/:id">
                  <NyMal />
                </Route>

                <Route exact path="/templates/:id/TemplatePreview}">
                  <TemplatePreviewPage />
                </Route>

                <Route exact path="/timeoversikt">
                  <VanligOversiktMenu />
                </Route>

                <Route exact path="/timeføring">
                  <TimeTrackerMenu />
                </Route>

                <Route exact path="/timeføring/:id">
                  <NewTimePage />
                </Route>

                <Route exact path="/arbeidsplan/:id">
                  <ArbeidsplanTime />
                </Route>

                <Route exact path="/uke/:id">
                  <FørteUkeTimer />
                </Route>

                <Route exact path="/timeSettings">
                  <TimeSettings />
                </Route>

                <Route exact path="/timeAdmin">
                  <AdminTimeOverview />
                </Route>

                <Route exact path="/arbeidsplan">
                  <TimeAdminPage />
                </Route>

                <Route exact path="/timeAdmin/editTime">
                  <EditTimeArbeidsplan />
                </Route>

                <Route exact path="/timeAdmin/nytid">
                  <NyTid />
                </Route>

                <Route exact path="/timegodkjenning">
                  <Timegodkjenning />
                </Route>

                <Route exact path="/timegodkjenning/uke">
                  <TimegodkjenningUser />
                </Route>

                <Route exact path="/timegodkjenning/uke/:id">
                  <WeekGodkjenning />
                </Route>

                <Route exact path="/cardscan">
                  <DefaultWrapper />
                </Route>

                <Route exact path="/createCompany">
                  <CreateCompanyPage />
                </Route>



                <Route path="/invitasjoner">
                  <Invitation />
                </Route>

                <Route exact path="/hms">
                  {CheckIfOnPC() ? <HMSOverview /> : <HMSMeny />}

                </Route>

                <Route exact path="/hms/sja/pdf">
                  <SJAPDF />
                </Route>

                <Route exact path="/hms/sja/:id">
                  <SJA />
                </Route>


                <Route exact path="/hms/ruh/pdf">
                  <RUHPDF />
                </Route>

                <Route exact path="/hms/ruh/:id">
                  <RUHSkjema />
                </Route>

                <Route exact path="/hms/avvik/pdf">
                  <Avvikpdf />
                </Route>

                <Route exact path="/hms/avvik/:id">
                  <Avvik />
                </Route>

                <Route exact path="/hms/:type">
                  <HMSViewType />
                </Route>



                <Route exact path="/hms/vernerunde">
                  <Vernerunde />
                </Route>

                <Route exact path="/hms/stoffkartotek">
                  <DefaultWrapper />
                </Route>

                <Route exact path="/instillinger">
                  <Instillinger />
                </Route>

                <Route exact path="/">
                  <HandleRedirect />
                  {/*hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <Redirect to="/company" />) : <Redirect to="/login" />*/}
                </Route>



                <Route exact path="/PermissionHandler">
                  <PermissionHandler />
                </Route>



                <Route path={["*", window.location.pathname]}>
                  <HandleRedirect />
                  {/*hasUserId() ? (company?.id ? <Redirect to="/hjem" /> : <Redirect to="/company" />) : <Redirect to="/login" />*/}
                </Route>
              </Switch>
            </IonRouterOutlet>
          </Suspense>
        </div>
      </ThemeProvider>


      <div id="hidden" style={{
        display: "none"
      }}></div>


    </IonReactRouter>
  )
}