import React, { useEffect, useRef, forwardRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/icons/material-symbols_edit.svg';
import { Visibility } from '@mui/icons-material';

const CustomInput = forwardRef((props, ref) => {
    const internalRef = useRef();
    const [hidePassword, setHidePassword] = useState(true);
    const [focused, setFocused] = useState(false);

    // Combine internal ref with external ref (if provided)
    const combinedRef = ref || internalRef;
    const suggestionRef = useRef();

    const handleVisibility = () => {
        if (props?.type === "password") return hidePassword ? "password" : "text";
        if (props?.type) return props.type;
        return "text";
    }

    useEffect(() => {
        if (props.shouldFocus && combinedRef.current) {
            combinedRef.current.focus();
        }
    }, [props.value, props.shouldFocus, combinedRef]);

    return (
        <div className={'customInput row center small-gap ' + props.outerClassName} onClick={() => {
            if (props.onClick) props.onClick();
            combinedRef.current.focus();
            setFocused(true);
        }}>
            {props?.label &&
                <label className={
                    (props.value || focused || props?.type === 'number' || props?.type === 'date' || props?.type == "time" || props?.beforeInput)
                        ? 'customInput_label customInput_label_active'
                        : 'customInput_label'
                }>{props?.label}</label>
            }
            {props?.beforeInput && <div className='beforeInput'>{props?.beforeInput}</div>}
            <input
                {...props}

                onFocus={(e) => {
                    if (props?.onFocus) props?.onFocus(e);
                    setFocused(true);
                }}
                ref={combinedRef}
                className={props.className}
                type={handleVisibility()}
                onChange={props.onChange}
                value={formatString(props?.value, props?.inputPattern)}
                placeholder={props?.label ? null : props?.placeholder}
                onClick={props?.onClick}
                onBlur={(e) => {
                    setTimeout(() => {
                        setFocused(false);
                    }, 100);
                    if (props.onBlur) props?.onBlur(e);
                }}
            />

            {props?.copy && <button type='button' onClick={() => { navigator.clipboard.writeText(value) }} className='copyButton'><ContentCopy /></button>}
            {props?.preview && <button type='button' onClick={() => { if (props?.value && isLink(value)) window.open(value) }}><Preview /></button>}
            {props?.type === "password" && <button type='button' onClick={() => { setHidePassword(!hidePassword) }}><Visibility /></button>}

            {!props.noIcon && props?.type !== "password" && <EditIcon />}

            {props?.suggestions && focused &&
                <div className="suggestions" ref={suggestionRef}>
                    {props?.suggestions.map((suggestion, index) => {
                        return (
                            <div className='row suggestion' key={index} onClick={() => {
                                props?.onSuggestion(suggestion);
                            }}>
                                <h3>{suggestion?.navn || suggestion?.name}</h3>
                                <h3>{suggestion?.organisasjonsnummer || suggestion?.orgNumber}</h3>
                                <h3>{suggestion?.forretningsadresse?.adresse[0] || suggestion?.businessAddress}</h3>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
});

export default CustomInput;

function formatString(stringUntempered, pattern) {
    if (!stringUntempered) return '';
    if (!pattern) return stringUntempered;
    if (pattern.length === 0) return stringUntempered;

    // Define the pattern of spaces
    const stringMax = pattern.reduce((acc, curr) => acc + curr, 0);
    const stringTakenAwayExcess = stringUntempered.replace(/\s/g, '').substr(0, stringMax);
    const str = stringTakenAwayExcess;
    let formatted = '';
    let index = 0;

    for (let len of pattern) {
        // Slice the next part of the string and add a space
        formatted += str.substr(index, len) + ' ';
        index += len;
    }

    // Trim any excess space and return
    return formatted.trim();
}
