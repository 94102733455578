import { createSlice } from '@reduxjs/toolkit';

const reportSlice = createSlice({
  name: 'report',
  initialState: {},
  reducers: {
    setReport: (state, action) => {
      return action.payload;
    },
    updateTaskValue: (state, action) => {
      const { oppgaveId, oppgaveEmneId, underAlignmentId, taskId, value, isFelles } = action.payload;
      
      const oppgave = state.oppgaver.find(oppgave => oppgave.id === oppgaveId);
      if (!oppgave) return;

      const oppgaveEmneItem = oppgave[isFelles ? 'felles' : 'oppgaveEmner'].find(item => item.id === oppgaveEmneId);
      if (!oppgaveEmneItem) return;

      const underAlignmentItem = oppgaveEmneItem.underAlignments.find(item => item.id === underAlignmentId);
      if (!underAlignmentItem) return;

      const taskItem = underAlignmentItem.tasks.find(item => item.id === taskId);
      if (!taskItem) return;

      taskItem.value = value;
    },
    addUnderAlignmentsToOppgaveEmne: (state, action) => {
      const { oppgaveId, oppgaveEmneId, isFelles, underAlignments } = action.payload;
      
      const oppgave = state.oppgaver.find(oppgave => oppgave.id === oppgaveId);
      if (!oppgave) return;

      const oppgaveEmneItem = oppgave[isFelles ? 'felles' : 'oppgaveEmner'].find(item => item.id === oppgaveEmneId);
      if (!oppgaveEmneItem) return;
      
      oppgaveEmneItem.underAlignments = [...oppgaveEmneItem.underAlignments, underAlignments];
    },
  },
});

export const { setReport, updateTaskValue, addUnderAlignmentsToOppgaveEmne } = reportSlice.actions;
export default reportSlice.reducer;
