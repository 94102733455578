export const checkOperator = (valueBeforeConversion, operator, item, key, convertionFunction) => {
    let value = convertionFunction ? convertionFunction(valueBeforeConversion) : valueBeforeConversion;
    let itemKey = convertionFunction ? convertionFunction(item?.[key]) : item?.[key];
    //console.log(value, operator, item, key)
    console.log("CHECKING OPERATOR: ", valueBeforeConversion, operator, item, key, convertionFunction)
    if (operator === "===") return itemKey === value;
    if (operator === "==") return itemKey == value;
    if (operator === "!==") return itemKey !== value;
    if (operator === "!=") return itemKey != value;
    if (operator === ">") return itemKey > value;
    if (operator === "<") return itemKey < value;
    if (operator === ">=") return itemKey >= value;
    if (operator === "<=") return itemKey <= value;
    if (operator === "includes") return itemKey.toLowerCase().includes(value.toLowerCase());
    if (operator === "arrayIncludes") return itemKey.some(tag => tag.toLowerCase().includes(value.toLowerCase()));
    if (operator === "startsWith") return itemKey.startsWith(value);
    if (operator === "endsWith") return itemKey.endsWith(value);
    if (operator === "exists") return item?.[key] !== undefined;
    if (operator === "notExists") return item?.[key] === undefined || item?.[key] === null;
    console.error("Invalid operator: ", operator);
    return false;
}

/**
 * 
 * @param {Array} operators  Array of objects with operator and value
 * @param {*} item 
 * @param {*} key 
 * @param {*} type 
 * @returns 
 */
export const checkOperators = (operators = [], item, key, type = "or") => {
    if (type === "or") {
        return operators.some(({ operator, value }) => checkOperator(value, operator, item, key));
    }
    if (type === "and") {
        return operators.every(({ operator, value }) => checkOperator(value, operator, item, key));
    }
    console.error("Invalid type: ", type);
    return true;
}


export const strictFilter = (items, filters,) => {
    if (filters.length === 0) return items;
    let itemsToSearch = [...items];
    console.log("FILTERS: ", filters)

    const strictFilters = filters.filter((f) => f.strictType === "strict");
    const semiStrictFilters = filters.filter((f) => f.strictType === "semi-strict");

    console.log("STRICT FILTERS: ", strictFilters)
    console.log("SEMI-STRICT FILTERS: ", semiStrictFilters)

    itemsToSearch = itemsToSearch.filter(item => {
        if (strictFilters.length > 0) {
            return strictFilters.every(({ field, operator, value, convertionFunction }) => {
                return checkOperator(value, operator, item, field, convertionFunction)
            });
        }
        return true;
    });

    itemsToSearch = itemsToSearch.filter(item => {
        if (semiStrictFilters.length > 0) {
            return semiStrictFilters.some(({ field, operator, value, convertionFunction }) => {
                return checkOperator(value, operator, item, field, convertionFunction)
            });
        }
    });

    if (!strictFilters.length && !semiStrictFilters.length) {
        itemsToSearch = itemsToSearch.filter(item => {
            return filters.filter((f) => !f.strict).some(({ field, operator, value, convertionFunction }) => {
                return checkOperator(value, operator, item, field, convertionFunction)
            });
        });
    }

    return itemsToSearch;
}

/**
 * Base filters for nonFBFilters.
 */
export const baseFilters = {
    excludeIfEnded: [
        {
            field: 'endDate', operator: ">", value: new Date(Date.now() + 10 * 60 * 1000).toISOString(), convertionFunction: (value) => {
                return new Date(value)
            }, strictType: "semi-strict"
        },
        { field: 'endDate', operator: "notExists", value: null, strictType: "semi-strict" },

    ],

}


export const arrayIncludesArray = (arrayToMatch, arrayToMatchWith) => {
    return arrayToMatch.some(tag => arrayToMatchWith.includes(tag));
}
