import { Autocomplete, TextField } from "@mui/material";
import { CustomPaper, customSxStyles } from "./styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { addCustomer, removeCustomer, updateCustomer } from "../../stores/customerSlice";
import { CollSub } from "../utils/hooks/sub/fetchCollection";
import { addProject, removeProject, updateProject } from "../../stores/projectsSlice";



/**
 * 
 * @param {object} props
 * @param {object.value} projectObject
 * 
 * @returns 
 */

export default function CustomerWithProjectAutocomplete({ value, onChange, onChoise }) {
    const { t } = useTranslation();
    const autocompleteValue = {
        projectName: value?.project?.name || value?.project?.navn || "",
        projectId: value?.project?.id || "",
        number: value?.project?.number || "",
        customerName: value?.customer?.name || "",
        customerId: value?.customer?.id || ""
    }
    const { customers, isLoading } = CollSub({
        selector: (state) => state.customers.allIds,
        path: 'klienter',
        updateFunction: updateCustomer,
        addFunction: addCustomer,
        removeFunction: removeCustomer,
        key: "customers",
        returnItemsByIdSelector: (state) => state.customers.byId,
        // Only the once created by the user
        filters: []
    });

    // Projects werent stored yet.
    const { projects, isLoadingProjects } = CollSub({
        selector: (state) => state.projects.allIds,
        path: 'prosjekter',
        key: "projects",
        updateFunction: updateProject,
        addFunction: addProject,
        removeFunction: removeProject,
        returnItemsByIdSelector: (state) => state.projects.byId,
        filters: [
            // TODO - ADD FILTER TO ONLY ADD THE ONCE THEY ARE IN - If not admin.
            //{ field: "sluttDato", operator: "<", value: new Date() }, // NOT ABLE TO USE DUE TO STORING AS STRING
        ]
    });


    const flattenedOptions = projects.reduce((acc, project) => {
        //console.log(project);
        const customer = customers.find(customer => customer.id === (project.customerConnection || project.firmaConnection));
        if (customer) {
            acc.push({
                projectName: project.name || project.navn,
                projectId: project.id,
                number: project.number || project.nummer || "",
                customerName: customer?.name ? customer?.name : t("No Customer", "Ingen Kunde"),

                customerId: customer?.id ? customer?.id : "noCustomer"
            });
        } else {
            acc.push({
                projectName: project.name || project.navn,
                projectId: project.id,
                number: project.number || project.nummer || "",
                customerName: t("No Customer", "Ingen Kunde"),
                customerId: "noCustomer"
            });
        }
        return acc;
    }, []).sort((a, b) => a.customerName.localeCompare(b.customerName));

    return (
        <Autocomplete
            PaperComponent={CustomPaper}
            id="Timeføring"
            value={autocompleteValue}
            options={flattenedOptions}
            filterOptions={(options, { inputValue }) => {
                try {
                    let results = [];
                    let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                    options.forEach(option => {
                        // Convert customerName and projectName to lowercase and check if they include the lowercase input value
                        if (option.customerName.toLowerCase().includes(lowerCaseInputValue)) {
                            results.push(option);
                        }
                        else if (option.projectName.toLowerCase().includes(lowerCaseInputValue)) {
                            results.push(option);
                        }
                    });

                    return results;
                } catch (error) {
                    console.error("Error filtering options:", error);
                    return []; // Return an empty array in case of error
                }
            }}
            groupBy={(option) => option.customerName}
            getOptionLabel={(option) => option?.number ? option?.number + " - " + option.projectName : option.projectName}
            //style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
            sx={{ ...customSxStyles }}
            renderInput={(params) => <TextField {...params} label={t("Project", "Prosjekt")} onChange={onChange} /*value={value?.name || ""}*/ />}
            renderGroup={(params) => (
                <div key={params.key} style={{ background: "var(--input-background)" }}>
                    <p className="orange" style={{ marginLeft: 10 }}>{params.group}</p>
                    <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                        {params.children}
                    </p>
                </div>
            )}
            fullWidth={true}
            onChange={(e, value) => {
                if (!value.projectId) return
                const chosen = {
                    project: projects.find(project => project.id === value.projectId),
                    customer: customers.find(customer => customer.id === value.customerId) || null
                }
                if (onChoise) onChoise(chosen);
            }}
        />
    )
}
