import CircleIcon from "../miniComponents/circleIcon";


import { ReactComponent as AlarmIcon } from '../../assets/icons/mdi_alarm.svg';
import CustomInput from "../miniComponents/customInput";
import Timer from "./timer";
import { CheckRounded, Mail, Settings, SubdirectoryArrowRightRounded } from "@mui/icons-material";
import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { useTranslation } from "react-i18next";
import { calculateHoursOfTimes } from "../../pages/timeføring/utils/timeCalculations";
import { createDateOutOfFirebaseTimestamp } from "../../pages/timeføring/timeføring";


export default function TimeCard({ time, onClick, onDelete, onEdit }) {
    //console.log(time);
    const { t } = useTranslation();

    return (
        <div className="time-card column" >
            <div className="row">
                <CircleIcon icon={AlarmIcon} />
                <CustomInput value={time?.title} onChange={onEdit} />
            </div>
            <div className="row">
                <div className="flexApart stretch-width">
                    <p>{t("Time:", "Tid:")}</p>
                    <div className="row">
                        <p>{createDateOutOfFirebaseTimestamp(time.startDate).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}</p>
                        <p>-</p>
                        <p>{createDateOutOfFirebaseTimestamp(time.endDate).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}</p>
                    </div>

                    <Timer startDate={createDateOutOfFirebaseTimestamp(time.startDate)} stopDate={createDateOutOfFirebaseTimestamp(time.endDate)} />
                </div>
            </div>
        </div>
    )
}



export function SimplifiedTimeCard({ time, onClick, onDelete, onEdit }) {
    const { t } = useTranslation();
    console.log(time);
    const {value, suffix} = calculateTimePassed(time);

    return (
        <div className="simple-time-card row small-gap" onClick={onClick} style={{
            cursor: (time?.status === "godkjent" || time?.status === "sent") ? "default" : "pointer"
        }}>
            <div className={"simple-time-card-time column center" + (time?.status === "godkjent" ? " good" : " ")} >
                <h1>{value + t(suffix, suffix)}</h1>
            </div>
            <button className="row stretch-width time-card center relative" style={{ position: "relative" }}>
                <div className="row stretch-width padding wrap">
                    <label className={'customInput_label customInput_label_active'}>{"Timeføring"}</label>
                    <div className="column small-gap">
                        <p className="noWrapText">{time?.project?.navn || time?.project?.name || time?.tittel || time?.kommentar || time?.status || t("No Information", "Ingen Informasjon")}</p>
                    </div>

                    <div className="column small-gap">
                        <p className="noWrapText">{time?.tilleg ? time.tilleg?.navn : "Normal"}</p>
                    </div>
                </div>
                {time?.status === "godkjent" && <CheckRounded></CheckRounded>}
                {time?.status === "sent" && <Mail></Mail>}
                {(!time?.status || time?.status === "laget") && <Settings></Settings>}
            </button>
        </div>
    )
}

export function SimplifiedTimeCardWeek({ weekData, onClick, onDelete, onEdit }) {
    const { t } = useTranslation();
    const { weekNumber, times, startDate, endDate, weekNumberYear } = weekData;

    const calculateTimePassed = () => {
        if (times.length === 0) {
            return '0'; // Return 0 hours if there are no time entries
        }

        let totalSeconds = 0;
        times.forEach(time => {
            if (!time.startDate || !time.endDate) return; // Skip if either startDate or endDate is missing
            const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
            const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
            totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
        });

        let totalHours = totalSeconds / 3600; // Convert total seconds to hours

        // If total hours are 100 or more, round to nearest whole number
        // Otherwise, round to nearest quarter hour
        if (totalHours >= 100) {
            return Math.round(totalHours).toString();
        } else {
            totalHours = Math.round(totalHours * 2) / 2; // Round to the nearest 0.5
            return totalHours.toString().replace('.', ','); // Replace dot with comma for Norwegian formatting
        }
    };


    const isAllTimesInWeekGodkjent = () => {
        return (times.every(time => time.status === "godkjent") && times.length > 0);
    }


    const renderDateRange = () => {
        // Use the provided startDate and endDate to display the date range
        return startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();
    };

    // If the week is beyond the current week, return null
    //console.log(weekNumber, weekNumberYear, getNorwegianWeekNumber(new Date()), new Date().getFullYear());

    if (weekNumber > getNorwegianWeekNumber(new Date())) {
        if (weekNumberYear === new Date().getFullYear()) {
            return null;
        }
    }


    return (
        <div className="simple-time-card row small-gap" onClick={onClick}>
            <div className={"simple-time-card-time column center" + (isAllTimesInWeekGodkjent() ? " good" : "")}>
                <h1>{calculateTimePassed() + t(" hours", " t")}</h1>
            </div>
            <button className="row stretch-width time-card center">
                <div className="column stretch-width small-gap no-overflow wrap">
                    <div className="row small-gap">
                        <h3>{t("Week", "Uke") + " " + weekNumber}</h3>
                    </div>
                    <p className="noWrapText">{renderDateRange()}</p>
                </div>
                <SubdirectoryArrowRightRounded />
            </button>
        </div>
    )
}

function getWeekStartDateForWeekNumber(weekNumber, year) {
    // Start with the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);

    // Norwegian week number system uses the nearest Thursday to calculate the first week
    const dayOfWeek = firstDayOfYear.getDay();
    const firstThursday = firstDayOfYear.getDate() + (dayOfWeek <= 4 ? 4 - dayOfWeek : 11 - dayOfWeek);

    // Calculate the start date of the given week number
    // Subtracting 3 because we added to get to the first Thursday, now we need to get back to Monday
    const startDate = new Date(year, 0, firstThursday + (weekNumber - 1) * 7 - 3);

    return startDate;
}


function getNorwegianWeekNumber(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Set the date to the nearest Thursday: current date + 3 - current day number
    // Make Sunday's day number 7
    newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

    // Get the first day of the year
    const yearStart = new Date(newDate.getFullYear(), 0, 1);

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    // Return the week number
    return weekNo;
}

export function calculateTimePassed(time) {
    //if (!time.endDate) return {value:"0", suffix: " t"}; // Return 0 hours if endDate is missing
    //console.log(time.startDate, time.endDate);
    const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
    const endDate = createDateOutOfFirebaseTimestamp(time.endDate || new Date()); // Use current date if endDate is missing

    // Calculate the difference in milliseconds
    const diffInMs = endDate - startDate;

    // Convert milliseconds to hours
    let hours = diffInMs / 3600000;

    // Round to the nearest 0.5 hours
    hours = Math.round(hours * 2) / 2;

    // IF WE EVER WANT TO DO SECONDS
    /*if (hours === 0 && diffInMs < 60000) {
        return {value:Math.round((diffInMs / 1000)), suffix: " s"};
    }*/
    
    // IF WE EVER WANT TO DO MINUTES
    /*if (hours === 0) {
        return {value:Math.round((diffInMs / 60000)), suffix: " m"};
    }*/

    // Replace . with , for Norwegian formatting
    return {value:hours.toString().replace('.', ','), suffix: " t"};
}

export function calculateAllTimePassed(times) {
    let totalHours = 0;
    times.forEach(time => {
        const {value, suffix} = calculateTimePassed(time);
        console.log(value, suffix);
        const newValue = suffix === " t" ? parseFloat(Number(value.replace(',', '.'))) : 0;
        totalHours += newValue;
    });

    return totalHours;
}
