import { Timestamp } from 'firebase/firestore';
import { createDateOutOfFirebaseTimestamp } from '../../pages/timeføring/timeføring';

export const timestampMiddleware = store => next => action => {
    // Helper function to convert Timestamps to Date objects
    const convertTimestamps = (obj) => {
        if (obj instanceof Timestamp) {
            return createDateOutOfFirebaseTimestamp(obj).toISOString();
        } else if (Array.isArray(obj)) {
            return obj.map(item => convertTimestamps(item));
        } else if (typeof obj === 'object' && obj !== null) {
            return Object.keys(obj).reduce((acc, key) => {
                acc[key] = convertTimestamps(obj[key]);
                return acc;
            }, {});
        }
        return obj;
    };

    // Check if action has a payload, and convert any Timestamps in the payload
    if (action?.payload) {
        action.payload = convertTimestamps(action.payload);
    }

    // Pass the action to the next middleware/reducer
    if (action) return next(action)
    else return null;
};
