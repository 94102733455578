import { useSelector } from "react-redux";
import { CompanyContext } from "../../contexts/companyContext";
import { getClients, getCompany } from "../../firebase";

import CustomModal from "../miniComponents/CustomModal";
import { useContext, useEffect, useState } from "react";






export default function CustomerModal({ visible, show, onChange, includeSelf, label, adaptToChoise, title, onClose }) {
    const [customers, setCustomers] = useState([]);
    const { companyDetails, company } = useSelector(state => state.company);
    
    

    useEffect(() => {
        const update = (querySnapshot) => {
            const clientList = [];
            querySnapshot.forEach((doc) => {
                const clientData = doc.data();
                clientList.push(clientData);
            });
            console.log(companyDetails)
            console.log(company)
            if (companyDetails) setCustomers([...clientList, companyDetails]);
            else if (company) setCustomers([...clientList, company]);
            else console.log("No company details or company");
        };

        const fetchClients = async () => {
            const clients = await getClients(update);
            setCustomers(clients);
        };
        fetchClients();
    }, []);

    useEffect(() => {
        console.log(companyDetails);
        console.log(customers?.find(e => e.id === companyDetails.id));
        if (companyDetails && !customers?.find(e => e.id === companyDetails.id)) setCustomers(old => {
            console.log([...old, companyDetails]);
            return [...old, companyDetails]
        });
    }, [companyDetails]);

    useEffect(() => {
        console.log(customers)
    }, [customers])
    /*
    useEffect(() => {
        if (includeSelf) {
            const fetchSelf = async () => {
                const company = await getCompany();
                console.log(company);
                setSelf(company);
            };
            fetchSelf();
        }
    }, [includeSelf]);
    */


    return (
        <CustomModal items={customers} visible={visible || show}
            onChoise={(e) => {
                if (onChange) onChange(e);
            }}
            adaptToChoise={adaptToChoise}
            onClose={() => {
                if (onClose) onClose();
            }}
            title={title}
            label={label}



        />
    )
}
