// store/tasksSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createId } from '../firebase';
import { ErrorHandler } from '../components/utils/errorHandler';


export const CustomerVersion = "1.0.1"
//old



export const createDefaultCustomer = () => {
    return {
        name: "",
        address: "",
        postalCode: "",
        city: "",
        orgNumber: "",
        contactPeople: [{ name: "", email: "" }],
        id: createId(),
        version: CustomerVersion,
        lat: null, 
        lng: null,
        endDate: null,
    }
}

const customerSlice = createSlice({
    name: 'customers',
    initialState: {
        byId: {},
        allIds: [],
    },
    reducers: {
        addCustomer: {
            reducer:
                (state, action) => {
                    const { document } = action.payload;
                    //state.byId[path] = subscription;
                    if (state.allIds.includes(document.id)) return;
                    state.allIds.push(document.id);
                    state.byId[document.id] = document;
                },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },
        updateCustomer: {
            reducer: (state, action) => {
                const { document } = action.payload;
                state.byId[document.id] = document;
            },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },

        removeCustomer: (state, action) => {
            //console.log(action.payload);
            const id = action.payload;
            state.allIds = state.allIds.filter(id => id !== id);
            delete state.byId[id];
        },

        resetCustomers: (state) => {
            state.allIds = [];
            state.byId = {};
        }
    },
});


export const { addCustomer, updateCustomer, removeCustomer, resetCustomers } = customerSlice.actions;

export default customerSlice.reducer;

const convertVersionToNumber = (version) => {
    if (!version) return 0;
    const versions = version.split('.');
    return Number(versions.join(''));
}

const convertToVersion = (customer) => {
    if (customer?.version === CustomerVersion) return customer;
    if (convertVersionToNumber(customer?.version) > convertVersionToNumber(CustomerVersion)) {
        // TODO - Maybe add major version differences, so sometimes it doesnt matter.
        // like 1.0.1 to 1.0.2 is not a big deal, but 1.0.7 to 1.1.0 is a big deal.
        ErrorHandler({
            error: new Error("Your customer is newer than the current version of the app. Please update the app."),
            message: "Your customer is newer than the current version of the app. Please update the app.",
            actions: [/*{ name: "Update", functionToExecute: () => { 
                // TODO - Add a link to the app store.
            } }*/]
        })
        return customer;
    }
    let customerTConvert = { ...customer };
    if (!customerTConvert.version) customerTConvert = convertToVersion1_0_1(customerTConvert);
    return customerTConvert;
}

/*{ Expected input 
    navn: "",
    addresse: "",
    postnummer: "",
    by: "",
    orgNummer: "",
    arbeidsplass: "",
    kontaktpersoner: [{ navn: "", email: "" }],
    id: createId(),
}*/
const convertToVersion1_0_1 = (customer) => {
    return {
        ...customer, // For now, should be strict later.
        name: customer.navn,
        address: customer.addresse,
        postalCode: customer.postnummer,
        city: customer.by,
        orgNumber: customer.orgNummer,
        contactPeople: customer.kontaktpersoner.map(person => ({ name: person.navn || "", email: person.email || "" })),
        id: customer.id,
        version: "1.0.1",
        lat: customer?.lat || null,
        lng: customer?.lng || null,
        endDate: null,
    };
}