




// authSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetTime } from './timeSlice';
import { resetSubscribers } from './utils/subscriptionObserver';
import { resetProjects } from './projectsSlice';
import { resetHMS } from './hmsSlice';
import { resetCustomers } from './customerSlice';
import { resetReports } from './reportsSlice';

const initialState = {
    companyId: null,
    company: null,
    companyDetails: null,
    moduleSettings: null,
    users: {
        byId: {},
        allIds: []
    }
};

export const resetCompany = createAsyncThunk('company/reset', async (dataPassed, { dispatch }) => {
    dispatch(resetC());
    dispatch(resetCompanySubs());
});

export const resetCompanySubs = createAsyncThunk('company/resetrest', async (dataPassed, { dispatch }) => {
    resetSubscribers();
    dispatch(resetTime());
    dispatch(resetProjects());
    dispatch(resetHMS());
    dispatch(resetCustomers());
    dispatch(resetReports());
});


const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompanyDetails: (state, action) => {
            state.companyDetails = action.payload;
            localStorage.setItem('companyDetails', action.payload);
        },
        setCompany: (state, action) => {
            state.company = action.payload;
            localStorage.setItem('company', action.payload);
        },
        setModuleSettings: (state, action) => {
            state.moduleSettings = action.payload;
            localStorage.setItem('moduleSettings', action.payload);
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload;
            console.log(action.payload)
            localStorage.setItem('companyId', action.payload);
        },
        setCId: (state, action) => {
            state.companyId = action.payload;
            console.log(action.payload)
            localStorage.setItem('companyId', action.payload);
        },
        resetC: (state) => {
            state.company = null;
            state.companyDetails = null;
            state.moduleSettings = null;
            state.companyId = null
        },
    },
});

export const { setCompanyDetails, setCompany, setModuleSettings, setCompanyId, setCId, resetC } = companySlice.actions

export default companySlice.reducer;
