// store/tasksSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createId } from '../firebase';

export const ReportVersion = "0.8.0"
//old



export const createDefaultReport = () => {
    return {

    }
}

const reportSlice = createSlice({
    name: 'reports',
    initialState: {
        templates: {
            byId: {},
            allIds: []
        },
        byId: {},
        allIds: [],
    },
    reducers: {
        addReport: {
            reducer:
                (state, action) => {
                    const { document } = action.payload;
                    //state.byId[path] = subscription;
                    if (state.allIds.includes(document.id)) return;
                    state.allIds.push(document.id);
                    state.byId[document.id] = document;
                },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },
        updateReport: {
            reducer: (state, action) => {
                const { document } = action.payload;
                state.byId[document.id] = document;
            },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },

        removeReport: (state, action) => {
            //console.log(action.payload);
            const id = action.payload;
            state.allIds = state.allIds.filter(id => id !== id);
            delete state.byId[id];
        },

        // templates
        addTemplate: {
            reducer:
                (state, action) => {
                    const { document } = action.payload;
                    //state.byId[path] = subscription;
                    if (state.templates.allIds.includes(document.id)) return;
                    state.templates.allIds.push(document.id);
                    state.templates.byId[document.id] = document;
                },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },

        updateTemplate: {
            reducer: (state, action) => {
                const { document } = action.payload;
                state.templates.byId[document.id] = document;
            },
            prepare: ({ document }) => {

                return {
                    payload: { document: convertToVersion(document) }
                };
            },
        },

        removeTemplate: (state, action) => {
            //console.log(action.payload);
            const id = action.payload;
            state.templates.allIds = state.templates.allIds.filter(id => id !== id);
            delete state.templates.byId[id];
        },

        resetReports: (state) => {
            state.allIds = [];
            state.byId = {};
            state.templates = {
                byId: {},
                allIds: []
            }
        }
    },
});


export const {
    addReport, updateReport, removeReport,
    addTemplate, updateTemplate, removeTemplate,
    resetReports
} = reportSlice.actions;

export default reportSlice.reducer;

/*const convertToVersion = (report) => {
    return report;
}*/

const convertVersionToNumber = (version) => {
    if (!version) return 0;
    const versions = version.split('.');
    return Number(versions.join(''));
}

const convertToVersion = (report) => {
    //console.log(report);
    return report;
    if (report?.version === ReportVersion) return report;
    if (convertVersionToNumber(report?.version) > convertVersionToNumber(ReportVersion)) {
        // TODO - Maybe add major version differences, so sometimes it doesnt matter.
        // like 1.0.1 to 1.0.2 is not a big deal, but 1.0.7 to 1.1.0 is a big deal.
        ErrorHandler({
            error: new Error("Your report is newer than the current version of the app. Please update the app."),
            message: "Your report is newer than the current version of the app. Please update the app.",
            actions: [{
                name: "Update", functionToExecute: () => {
                    // TODO - Add a link to the app store.
                }
            }
            ]
        })
        return report;
    }
    let reportTConvert = { ...report };
    if (!reportTConvert.version) reportTConvert = convertToVersion1_0_1(reportTConvert);
    return reportTConvert;
}

const convertToVersion1_0_1 = (report) => {
    //console.log(Array.isArray(report.kontaktpersoner));
    return {
        ...report, // For now, should be strict later.
        name: report.navn,
        address: report.addresse,
        postalCode: report.postnummer,
        city: report.by,
        contactPeople: Array.isArray(report.kontaktpersoner) ? report?.kontaktpersoner?.map(person => ({ name: person.navn || "", email: person.email || "", id: createId() })) : [],
        id: report.id,
        version: "1.0.1",
        lat: report?.lat || null,
        lng: report?.lng || null,
        customerConnection: report?.firmaConnection,
        id: report?.id,
        version: ReportVersion,
        access: {
            users: report?.access?.users || [],
            roles: report?.access?.roles || []
        },
        number: report?.nummer || "",

        // Creation date
        creationDate: new Date().toISOString(),

        // Start and end of the report
        startDate: null,
        endDate: null,


        // Timetracking
        normalStart: report?.klokkeStart || "00:00",
        normalEnd: report?.klokkeSlutt || "00:00",
    };
}
