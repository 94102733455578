
import { IonButton, IonContent, IonDatetime, IonHeader, IonModal, IonPage, IonTitle, IonToast, IonToolbar, createAnimation } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { createGesture } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from "react";
import TilbakeKnapp from "../components/miniComponents/tilbakeKnapp";

import { ReactComponent as SearchIcon } from '../assets/icons/material-symbols_search.svg';
import { searchObjects } from "./search";
import Background from "./miniComponents/background";
import { CheckIfOnPC, HeaderContext } from "../App";
import Header from "./header";
import { Capacitor } from "@capacitor/core";
import { search } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import MenuButton from "./header/menuButton";

export default function DefaultWrapper(props) {
    const history = useHistory();
    const pageElRef = useRef();

    const [toastMessage, setToastMessage] = useState({ message: "", type: "", actions: [] });


    useEffect(() => {
        const setToastMsg = (event) => {
            const { message, type, actions } = event.detail;
            setToastMessage(() => {
                return { message: message, type: type, actions };
            });
        };

        window.addEventListener("customError", setToastMsg);
        /*
        const originalConsoleError = console.error;
        const originalConsoleInfo = console.info;


        console.error = (message, ...optionalParams) => {
            setTimeout(() => {
                //console.log("toast")
                //console.log(message);
                setToastMessage(() => {
                    return { message: message, type: "error" };
                });
            }, 10);
            originalConsoleError(message, ...optionalParams);
        };

        console.info = (message, ...optionalParams) => {
            setTimeout(() => {
                //console.log("toast")
                console.log(message);
                setToastMessage(() => {
                    return { message: message, type: "info" };
                });
            }, 1000);
            originalConsoleInfo(message, ...optionalParams);
        };

        if (Capacitor.isNativePlatform()) {
            const originalConsoleLog = console.log;
            */
        /*
        console.log = (message, ...optionalParams) => {
            originalConsoleLog(JSON.stringify(message, ...optionalParams));
        }
        */
        /*
         function logWithSource(message, ...optionalParams) {
             const stack = new Error().stack;
             originalConsoleLog(`${message}\nStack Trace: ${stack}`);
         }

         console.log = logWithSource;

         
     }*/

        return () => {
            //console.error = originalConsoleError;
            window.removeEventListener("customError", setToastMsg);
        };

    }, []);

    /*useEffect(() => {
        if (pageElRef.current) {
            const gesture = createGesture({
                el: pageElRef.current,
                threshold: 15,
                gestureName: 'swipe-back',
                onStart: () => {
                    // Gesture started
                },
                onMove: detail => {
                    // Gesture is moving
                    if (detail.deltaX > 200) {
                        // Swipe distance is far enough, go back
                        history.goBack();
                    }
                },
                onEnd: () => {
                    // Gesture ended
                },
            });

            gesture.enable();
        }
    }, [history]);*/


    return (
        <IonPage>
            <div ref={pageElRef} className={"app-content " + (props?.header ? " wrapperHeaderContent " : "")}>
                
                <Toast message={toastMessage} setToastMessage={setToastMessage} />
                {!props.noHeader &&
                    <div className={
                        "row flexApart wrap center-column top-menu " +
                        (!props.noPaddingTop ? (Capacitor.getPlatform() === "ios" ? "padding ios-padding-top" : "padding") : "") +
                        (props?.limitWidth ? " limit-width" : "") + (props?.bottomPadding ? " bottom-padding" : "")
                    }>
                        <div className="row small-gap">
                            {!props?.noMenuButton && !CheckIfOnPC() && <MenuButton />}
                            {!props?.noBackButton && <TilbakeKnapp lagring={props?.lagring} onClick={props.onBack} skipNormalBehaviour={props.skipBack} />}
                        </div>
                        <div className="row small-gap">
                        {props?.extraButton && props.extraButton}

                        {props?.søkefelt &&
                            <Søkefelt updateSearchString={props?.updateSearchString} updateFilteredItems={props?.updateFilteredItems} itemsToFilter={props?.itemsToFilter} searchTerms={props?.searchTerms} onSearchStringChange={props.onSearchStringChange} doNotShowScoreUnderZero={props?.doNotShowScoreUnderZero} />
                        }
                        </div>
                    </div>
                }
                {props.children}
                {props?.header && <Header />}
            </div>
        </IonPage>
    );
}


export function Søkefelt(props) {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [searchString, setSearchString] = useState('');
    //console.log(searchString, props.itemsToFilter, props.searchTerms)
    useEffect(() => {
        if (typeof props?.onSearchStringChange === 'function') return props.onSearchStringChange(searchString);
        if (typeof props?.updateSearchString === 'function') return props.updateSearchString(searchString);
    }, [searchString]);

    useEffect(() => {
        filterBrukere();
    }, [searchString, props.itemsToFilter, props.searchTerms]);

    function sortItems(items, sortBy) {
        if (!Array.isArray(items)) {
            console.error('Expected items to be an array, but got:', items);
            return [];
        }
        // Assuming you have a function to sort items based on props.sortBy
        return items.sort((a, b) => a[sortBy] - b[sortBy]);
    }

    function filterBrukere() {
        const itemsToFilter = Array.isArray(props.itemsToFilter) ? props.itemsToFilter : [];
        const searchTerms = Array.isArray(props.searchTerms) ? props.searchTerms : [];

        let filtered = [];

        if (searchString === '') {
            filtered = sortItems(itemsToFilter, props.sortBy);
        } else {
            const searchStr = typeof searchString === 'string' ? searchString : '';
            filtered = props?.doNotShowScoreUnderZero
                ? searchObjects(itemsToFilter, searchStr, searchTerms).filter((item) => item.score > 0)
                : searchObjects(itemsToFilter, searchStr, searchTerms);
        }

        if (typeof props.updateFilteredItems === 'function') {
            if (searchString === '') filtered = filtered.map((item) => ({ ...item, score: 1 }));
            console.log(filtered);
            filtered.forEach((item) => {
                console.log(item.score);
            })
            props.updateFilteredItems(filtered);
        } else {
            //console.error('Expected props.updateFilteredItems to be a function, but got:', props.updateFilteredItems);
        }
    }

    return (
        <button className="søkefelt" onClick={() => inputRef.current?.focus()}>
            <input className="søkefelt-input" onChange={(e) => setSearchString(e.target.value)} ref={inputRef} type="text" placeholder={t("Search", "Søk")} />
            <SearchIcon />
        </button>
    );
}



// TODO - Move all of this to its own file. Make the Toast be its own component, in the top of the app.
// Should have its own listener. Remove Ionic/IonToast from here.
const Toast = ({ message, setToastMessage, actions }) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        //console.log("toast useEffect")
        if (message?.message?.length > 0) {
            setVisible(true);
        }

        const timer = setTimeout(() => {
            setToastMessage({ message: "", type: "" });
            setVisible(false);
        }, 7000);
        return () => {

            clearTimeout(timer)
        };
    }, [message]);


    return (
        <IonToast
            position="top"
            isOpen={visible}
            onDidDismiss={() => setVisible(false)}
            message={message.message}
            duration={7000}
            color={message.type === "error" ? "danger" : "primary"}
            buttons={actions && actions.length ? 
                actions.map((action, index) => {
                    return {
                        text: action.name,
                        handler: () => {
                            action.functionToExecute();
                            setToastMessage({ message: "", type: "" });
                        }
                    }
                })
                :
                [
                    {
                        text: t("Cancel", 'Avvis'),
                        role: 'cancel',
                        handler: () => {
                            console.log('Dismiss clicked');
                        },
                    },
                ]}
            onClick={() => {
                setVisible(false);
                if (functionToExecute) functionToExecute();
                setToastMessage({ message: "", type: "" });
            }}
        />
    );
};


