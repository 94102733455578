import { collection } from "firebase/firestore";
import { db, getFirmSettings } from "../firebase";
import store from "../components/store";





export async function getCompanyTimeSettings() {
    const settings = await getFirmSettings("timeSettings");
    console.log(settings)
    return { value: settings };
}

export function getTimeById(id) {

    const docRef = doc(db, 'Kompanier', companyId(), 'tid', store.getState().user.user.uid, 'timer', id);
    const fetchedDoc = getDoc(docRef);
    return fetchedDoc.data();
}

/* EXAMPLE SETUP
export async function getRoles(params, onUpdate) {
    // Get an array of the roles in the company
    const q = query(collection(db, "Kompanier", companyId(), "roles"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const roles = [];
        querySnapshot.forEach((doc) => {
            roles.push(doc.data());
        });
        onUpdate(roles);
    });

    // return roles
    const documents = await getDocs(q);
    const roles = [];
    documents.forEach((doc) => {
        roles.push(doc.data());
    });
    return {value: roles, subscription: unsubscribe};
}
*/



export async function setTimeListForUser(newTimeList, user, dato) {
    // Parse the dato string to a Date object
    const dateCET = moment.tz(dato, "Europe/Berlin");

    // Format the date part and combine with the time part from startDate and endDate
    const startDateTime = dateCET.format('YYYY-MM-DD') + 'T' + newTimeList.startDate + ':00';
    const endDateTime = dateCET.format('YYYY-MM-DD') + 'T' + newTimeList.endDate + ':00';

    // Parse the combined date and time strings as CET time
    const startDate = moment.tz(startDateTime, "Europe/Berlin").toDate();
    const endDate = moment.tz(endDateTime, "Europe/Berlin").toDate();


    const timelist = {
        ...newTimeList,
        startDate, // Ensure these are Date objects
        endDate,
        dato: dato, // if you still want to keep the 'dato' field
        userUID: user.id,
        user: user,
        id: createId(),
        notificationSent: false,
        // ... rest of the fields
    };

    console.log(timelist);

    await setDoc(doc(db, "Kompanier", companyId(), "tid", "timeliste", "liste", timelist.id), timelist);
}


export async function updateTimeListForUser(newTimeList, user, dato) {
    const functions = getFunctions(app, 'europe-west2');
    const updateTimeList = httpsCallable(functions, 'updatePlannedTime');

    const date = new Date(dato);

    // Extract the date part from the Date object in YYYY-MM-DD format
    const datePart = date.toISOString().split('T')[0];

    console.log(datePart);
    console.log(newTimeList.startDate);
    console.log(newTimeList.endDate);

    // Concatenate the date part with the time part from startDate
    const startDateTime = `${datePart}T${newTimeList.startDate}:00`;
    const endDateTime = `${datePart}T${newTimeList.endDate}:00`;

    // Create Date objects from the concatenated strings
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    const timelist = {
        ...newTimeList,
        startDate,
        endDate,
        dato: date, // if you still want to keep the 'dato' field
        userUID: user.id,
        user: user,
        notificationSent: false,
    };

    const dataToSend = {
        timelist: timelist,
        companyId: companyId(),
    }

    try {
        const result = await updateTimeList(dataToSend);
        console.log(result.data.message);
        return result.data;
    } catch (error) {
        console.error('Error calling updateTimeList:', error);
        throw error;
    }
}

export async function deleteTimeListForUser(timelist) {
    const functions = getFunctions(app, 'europe-west2');
    const deleteTimeList = httpsCallable(functions, 'deletePlannedTime');

    const dataToSend = {
        timelist: timelist,
        companyId: companyId(),
    }

    try {
        const result = await deleteTimeList(dataToSend);
        console.log(result?.data?.message);
        return result?.data;
    } catch (error) {
        console.error('Error calling deleteTimeList:', error);
        throw error;
    }
}

export async function getTimeListForUser(onUpdate, userUID) {
    // Only get times after yesterday
    console.log("getTimeListForUser")
    console.log(new Date(new Date().setDate(new Date().getDate() - 32)))
    const q = query(collection(db, "Kompanier", companyId(), "tid", "timeliste", "liste"), where("userUID", "==", userUID));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const timeList = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            //timeList.push(data);
            if (!data.gjennomført) {
                timeList.push(data);
            }
        });
        onUpdate(timeList, userUID);
    }
    );
}

export async function getTimeListForUserWithRestricitons(onUpdate, userUID, datoFrom, datoTo) {
    // Only get times after yesterday
    const q = query(collection(db, "Kompanier", companyId(), "tid", "timeliste", "liste"), where("userUID", "==", userUID), where("startDate", ">=", datoFrom), where("startDate", "<=", datoTo), orderBy("startDate", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const timeList = [];
        querySnapshot.forEach((doc) => {
            timeList.push(doc.data());
        });

        console.warn("getTimeListForUserWithRestricitons", timeList);
        onUpdate(timeList, userUID);
    }
    );
}

export async function getTimeListForAllUsers(onUpdate, from, to) {
    const q = query(collection(db, "Kompanier", companyId(), "tid", "timeliste", "liste"), where("startDate", ">=", from), where("startDate", "<=", to), orderBy("startDate", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const timeList = [];
        querySnapshot.forEach((doc) => {
            timeList.push(doc.data());
        });
        onUpdate(timeList);
    }
    );
}