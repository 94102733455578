import { Doughnut } from "react-chartjs-2";
import { calculateHoursOfTimes, getDoughnutData } from "./timeCalculations";
import { useTranslation } from "react-i18next";

export const DOUGHNUT_COLORS = [
    '#A155B9',
    '#1DDD8D',
    '#F765A3',
    '#16BFD6',
    '#165BAA',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
];

export const doughnutOptions = {
    layout: {
        padding: {
            right: 0, // Or whatever value needed to achieve the desired effect
        },
        autoPadding: false,
    },
    plugins: {
        legend: {
            display: false,
            position: 'right',
            align: 'start',

            labels: {
                align: 'start',
                color: '#FFFFFF', // Set text color
                font: {
                    size: 10, // Set font size
                    family: 'Arial', // Set font family
                },
                boxWidth: 20,

            }
        }

    },
    cutout: '70%',
    // Add responsive: true to make the chart adjust to its container
    responsive: true,
    maintainAspectRatio: false // Set to false to allow custom dimensions
};

export default function TimetrackingDoughnut({ times }) {
    const { t } = useTranslation();
    const data = getDoughnutData(times);
    return (
        <div className="time-card row" style={{
            width: "100%",
            height: "150px",
            position: "relative",
            padding: "20px",
            overflow: "auto"
        }}>
            <h2 style={{ position: "absolute", top: "50%", left: "75px", transform: "translate(-50%, -50%)" }}>{calculateHoursOfTimes(times).toString().replace('.', ',') || 0} {t("h", "t")}</h2>
            <div style={{ width: "110px", height: "100%", zIndex:1 }}>
                {/*<Doughnut data={doughnutData} options={options} color={"#FFFFFF"} style={{ width: "310px" }} /> */}
                <Doughnut color={"#FFFFFF"} style={{ width: "310px" }}  data={data} options={doughnutOptions} />
            </div>
            <div className="column wrap" style={{ paddingBlock: 7.5 }}>
                {data.labels.map((label, index) => {
                    return (
                        <div className="row small-gap small-text center" key={index}>
                            <div style={{ backgroundColor: DOUGHNUT_COLORS[index], borderRadius: "50%", width: "1em", height: "1em" }}></div>
                            <p>{label}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
