import { Timestamp } from "firebase/firestore";
import CircleIcon from "./miniComponents/circleIcon";
import { createDateOutOfFirebaseTimestamp } from "../pages/timeføring/timeføring";
import { useTranslation } from "react-i18next";

export default function RapportCard(props) {
    const { t } = useTranslation();
    const { firma, dato, bruker, id } = props.report;

    //console.log(dato)
    //const date = new Timestamp(dato).toDate()
    const date = createDateOutOfFirebaseTimestamp(dato)
    //console.log(date)

    //console.log(date.getDate())

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    //console.log(day, month, year)
    let currentDate = `${day}-${month}-${year}`;

    return (
        <button className='column button-list-button top-left' onClick={() => { if (props?.onPress) props.onPress(props.report); }}>
            <div className='row stretch-width '>
                <CircleIcon icon={props.icon} />
                <div className='column stretch-width'>
                    <div className='flexApart stretch-width'>
                        <h3>{firma.name || firma.navn || t("No Company Selected", "Ingen firma Valgt")}</h3>
                        <h3>Nr.{id}</h3>
                    </div>
                    <div className='flexApart stretch-width'>
                        <p>{currentDate}</p>
                        <p>{bruker?.name || ""}</p>
                    </div>
                </div>
            </div>
        </button>
    );
};


/*
{
    oppgaver: [
        {
            title: "Mål",
            kanHaFlere: true,
            id: "123"
            underAlignments: [
                {
                    name: "stk",
                    type: "number"
                },
                {
                    name: "kr",
                    type: "number"
                },
                {
                    name: "timer",
                    type: "number"
                }
            ]
        }
    ]
}*/