import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import PcHeader from "../PCHeader";






export default function MenuButton({ }) {
    const { t } = useTranslation();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const closeMenu = () => {
        setOpen(false);
    }

    return (
        <>
            <button className="header-menu-button phone-header-button" onClick={() => setOpen(!open)}>
                <Menu className="phone-header-button-icon"></Menu>
            </button>
            {open ? <PcHeader className="phone-header" closeMenu={closeMenu} /> : null}
        </>
    );
}