import CircleIcon from "../miniComponents/circleIcon";
import Timer from "./timer";
import { ReactComponent as AlarmIcon } from '../../assets/icons/mdi_alarm.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getDay, getDayInMonth, getMonth, CustomPaper, customSxStyles } from "../../pages/timeføring/timeføring";
import { useFetchSingleEditableRS, useSingleFetchEditableRS } from "../utils/hooks/RSFetchSingleEditable";
import { checkIfRCTimeCompleted, completeTime, createDefaultTime, setPrevRcTimes, setRcTime, setRcTimeAsync } from "../../stores/timeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { checkForTimeOverlap, createId, uploadTimeToDb, uploadTimeToDbRS } from "../../firebase";
import ButtonOnlyHitOnce from "../miniComponents/buttonOnlyHitOnce";
import { ShortcutRounded, SquareRounded } from "@mui/icons-material";

import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/ph_play-fill.svg';
import { useTranslation } from "react-i18next";
import useCollectionSubscriptionRS from "../utils/hooks/RSfirebaseCollectionSubscriber";
import { storeEditable } from "../utils/hooks/storeSingleEditable";
import CustomInput from "../miniComponents/customInput";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import CustomerWithProjectAutocomplete from "../autocomplete/CustomerWithProjectAutocomplete";
import { useHistory } from "react-router";
import { CheckIfOnPC } from "../../App";

export function PcStartAndStopTimer({ minimized = false }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const ts = useSelector(state => state.time.prevRcTimes)
    const history = useHistory();


    const { rcTimes, isLoading } = useCollectionSubscriptionRS({
        selector: (state) => state.time.prevRcTimes,
        path: `tid/${user.uid}/timer`,
        setFunction: setPrevRcTimes,
        key: "rcTimes",
        payload: {},
        // Only the once created by the user
        filters: [
            { field: 'endDate', operator: '==', value: null }
        ]
    });
    const { time, setTime } = storeEditable({
        selector: state => state.time.rcTime,
        setFunction: setRcTimeAsync,
        key: "time"
    })

    useEffect(() => {
        dispatch(checkIfRCTimeCompleted())
    }, [])

    return (
        <div className="column noGap">
            <div className={"row small-gap time-top " + (minimized ? " flexApart " : "")}>
                <h2 className="small-text row">
                    <div className="small-circle" style={{
                        backgroundColor: time?.startDate ? "var(--bad)" : "var(--good)",
                        width: "1em",
                        height: "1em",
                        borderRadius: "50%",
                    }}></div>
                    {getDay(t)}, {getDayInMonth(new Date(), t)}. {getMonth(new Date(), t)}
                </h2>
                <Timer className="small-text" startDate={time?.startDate} endDate={time?.endDate} time={time} />
            </div>
            <div className={"row stretch-width center-column time-bottom center " + (minimized ? "" : " wrap-on-phone ")}>
                <div className={"row center-column " + (minimized && !CheckIfOnPC() ? " hidden " : " stretch-width ")}>
                    <CircleIcon good={!time?.startDate} recording={time?.startDate} icon={AlarmIcon} />
                    <div className={"row flexApart stretch-width wrap-on-phone" + (minimized && !CheckIfOnPC() ? " hidden " : "")}>
                        <div className="row stretch-width">
                            <CustomerWithProjectAutocomplete value={time} onChange={(e) => {
                                setTime(old => ({
                                    ...old, project: {
                                        name: e.target.value,
                                    }
                                }))
                            }}
                                onChoise={({ project, customer }) => {
                                    setTime(old => ({ ...old, project: project, customer: customer }))
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={"row small-gap " + (minimized && !CheckIfOnPC() ? " flexApart stretch-width" : "") }>

                    <Tooltip title={t("Pause", "Pause")} placement="top">
                        <button className={"btn btn-primary icon-only-button stretch-height one-to-one" + (time?.pause ? " active" : "")} onClick={
                            () => {
                                let timeCopy = { ...time }
                                timeCopy.pause = timeCopy.pause ? false : {
                                    hours: 0,
                                    minutes: 30,
                                }
                                setTime(timeCopy)
                            }
                        }> <PauseIcon /></button>
                    </Tooltip>

                    {!minimized &&
                        <CountdownTimerWithControls time={time} setTime={setTime} />
                    }

                    {!time?.startDate ?
                        <Tooltip title={t("Start", "Start")} placement="top">
                            <ButtonOnlyHitOnce key="start-button" className="btn btn-primary good icon-only-button stretch-height one-to-one" onClick={
                                async () => {
                                    const tempUpdate = {
                                        ...time,
                                        startDate: new Date(),
                                        //stoppet: false,
                                    }
                                    setTime(tempUpdate)
                                }
                            }><PlayIcon /></ButtonOnlyHitOnce>
                        </Tooltip>
                        :
                        <Tooltip title={t("Stop", "Stopp")} placement="top">
                            <button key="stop-button" className="btn btn-primary recording icon-only-button stretch-height one-to-one active" onClick={
                                () => {
                                    let timeCopy = { ...time }
                                    timeCopy.endDate = new Date()
                                    setTime(old => timeCopy)
                                    //const overlap = checkForOverlap(timeCopy)
                                }
                            }> <SquareRounded /></button>
                        </Tooltip>
                    }

                    {minimized && <button className="btn btn-primary icon-only-button stretch-height one-to-one" onClick={() => {
                        history.push("/timeføring")
                    }}> <ShortcutRounded /></button>


                    }
                </div>
            </div>
        </div >
    )
}




const ChooseFrom = ({ time, setTime }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const time_timeString = time?.startDate ? new Date(time?.startDate).toLocaleTimeString() : ""
    const [localState, setLocalState] = useState(time_timeString)

    useEffect(() => {
        setLocalState(time_timeString)
    }, [time])

    if (!time?.startDate) return console.log("No start date")
    return (
        <div className="column small-gap">

            <CustomInput
                type="time" value={localState}
                onChange={(e) =>
                    setLocalState(old => e.target.value)
                }
                noIcon={true}
                onBlur={async () => {
                    console.log(localState)
                    const tempUpdate = {
                        ...time,
                        startDate: setNewTime(time?.startDate, localState)
                    }
                    if (localState === time_timeString || localState === "") return
                    console.log(tempUpdate)
                    setTime(tempUpdate)
                }}
            />
        </div>
    )
}

/**
 * 
 * @param {Date || ISOString} date Date Object or ISOString
 * @param {String} time "HH:MM:SS"
 * @returns 
 */
const setNewTime = (date, time) => {
    console.log(date, time)
    let newDateTime = new Date(date)
    const [hours, minutes, seconds] = time.split(":")
    newDateTime.setHours(hours)
    newDateTime.setMinutes(minutes)
    newDateTime.setSeconds(seconds || 0)
    return newDateTime
}


export function CountdownTimerWithControls({ time, setTime, count = true }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const ts = useSelector(state => state.time.prevRcTimes)
    const [open, setOpen] = useState(false)
    const timerRef = useRef(null)
    //console.log(time)

    return (
        <div className="column small-gap center">
            <button className="column small-gap timer-button"
                style={{ fontSize: "1.5rem", minWidth: 115 }}
                ref={timerRef}
                onClick={() => setOpen(old => !old)}
            >
                <Timer
                    showSeconds
                    showSecondsIfNoHour={false}
                    startDate={time?.startDate ? new Date(time?.startDate) : null}
                    countUp={count ? time?.startDate !== null : false}
                    stopDate={time?.endDate ? new Date(time?.endDate) : null}
                />
            </button>
            <Menu anchorEl={timerRef.current}
                id="start-time-menu"
                open={open}
                className="padding"
                onClose={() => setOpen(false)}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <div className="padding column">
                    <h2>{t("Start time", "Starttid")}</h2>
                    <ChooseFrom time={time} setTime={setTime} />
                </div>
            </Menu>
        </div>
    )
}