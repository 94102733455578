

export const ErrorHandler = ({
    error = {},
    errorMessage = "",
    throwError = false,
    actions = []
}) => {
    if (throwError) throw error;
    const event = new CustomEvent("customError", {
        detail: {
            error: error,
            errorMessage: errorMessage,
            throwError: throwError,
            actions: actions,
        }
    });
    window.dispatchEvent(event);
}