//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
/*import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { update, doc, query, onSnapshot, addDoc, updateDoc, setDoc, where, getDoc, deleteDoc, getFirestore, collection, getDocs, orderBy, limit } from "firebase/firestore";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect, signInWithCredential } from "firebase/auth";
import { initializeApp } from "firebase/app";*/
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { update, doc, query, onSnapshot, addDoc, updateDoc, setDoc, where, getDoc, deleteDoc, getFirestore, collection, getDocs, orderBy, limit, serverTimestamp, limitToLast } from "firebase/firestore";
//import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import firebase, { deleteApp, getApp, initializeApp, } from "firebase/app";
import { Plugins, Capacitor } from '@capacitor/core';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithRedirect, signInWithCredential, indexedDBLocalPersistence, getRedirectResult, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updateProfile, sendEmailVerification, initializeAuth, EmailAuthProvider, } from "firebase/auth";
import { getMessaging, getToken, onMessage, Notifica } from "firebase/messaging";
// Import capacitator local notifications
import { LocalNotifications, addListener } from '@capacitor/local-notifications';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { PushNotifications } from '@capacitor/push-notifications';

import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { add, send } from "ionicons/icons";
import { v4 as uuidv4 } from 'uuid';
//import { stat } from "fs";
import { combineAllToCheckPermissions } from "./components/utils";
//import { getCurrentPosition } from "./pages/settings/geolocation";
import { getDistance } from 'geolib';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { createDateOutOfFirebaseTimestamp } from "./pages/timeføring/timeføring";
import moment from 'moment-timezone';
import { setCompanyId } from "./contexts/companyContext";
import { useDispatch } from "react-redux";
import store from "./stores/mainStore";



// Import the functions you need from the SDKs you need
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDW-AVuuz6ihnGy7xPGoYuEL2aRGhRJS5I",
    authDomain: "fir-gruppen-app.firebaseapp.com",
    projectId: "fir-gruppen-app",
    storageBucket: "fir-gruppen-app.appspot.com",
    messagingSenderId: "61081567253",
    appId: "1:61081567253:web:1a9a54f07aaab2c91b4e1c",
    measurementId: "G-H2JEGHRNSL"
};

export var app = initializeApp(firebaseConfig);


const getFirebaseAuth = async () => {
    if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        return getAuth(app);
    }
};

export const auth = await getFirebaseAuth();
export const db = getFirestore(app);
const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "localhost", 5001);

// Initialize Firebase

//export const messaging = getMessaging(app);
/*if (Capacitor.isNativePlatform()) {
    initializeAuth(app, {
        persistence: indexedDBLocalPersistence,
    });
} */






export const checkPermissions = async () => {
    const result = await FirebaseMessaging.checkPermissions();
    return result.receive;
};

export const requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    return result.receive;
};

export const addTokenReceivedListener = async () => {
    await FirebaseMessaging.addListener('tokenReceived', event => {
        console.log('tokenReceived', { event });
    });
};

export const addNotificationReceivedListener = async () => {
    await FirebaseMessaging.addListener('notificationReceived', event => {
        console.log('notificationReceived', { event });
        handleReceivedNotification(event);
    });

    console.log("Adding notification received listener")

    await PushNotifications.addListener('pushNotificationReceived', async (notification) => {
        console.log('Push received: ', notification);
    });

    console.log("Adding notification opened listener")
};


/*function makeAuth() {
    let auth;
    if (Capacitor.isNativePlatform()) {
        console.log("Native platform")
        auth = FirebaseAuthentication.getAuth(app) /*initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        console.log("Web platform")
        auth = getAuth(app);
        onAuthStateChanged(auth, (user) => {
            console.log("Auth state changed");
            console.log(user);
            if (user) {
                console.log("User signed in:", user.uid, user.email);
                userToDist = user;
            } else {
                console.log("No user signed in");
            }
        });
    }
    return auth;
}*/



console.log(Capacitor.isNativePlatform());
//const analytics = getAnalytics(app);
var userToDist = null;

//const provider = new GoogleAuthProvider();


console.log(auth);

const containsInUrl = (string) => {
    console.log(window.location.pathname)
    console.log(string)
    console.log(window.location.pathname.includes(string))
    return window.location.pathname.includes(string)
}

// Company ID by viewing
export function getCompanyId() {
    // Based on localStorage
    let companyId = store.getState().company.companyId;
    //localStorage.getItem("companyId");//localStorage.getItem("currentlyViewingCompany");
    //console.log("Currently viewing company: ", companyId);

    return companyId;
}

//const companyId = getCompanyId();


export let companyId = () => getCompanyId()


export async function setCompanyDetails(details) {
    const companyDocRef = doc(db, 'Kompanier', companyId(), 'details', 'details');
    await setDoc(companyDocRef, details);
}

export async function getCompanyDetails() {
    if (!companyId()) return null;
    const companyDocRef = doc(db, 'Kompanier', companyId(), 'details', 'details');
    const companyDoc = await getDoc(companyDocRef);
    return companyDoc.data();
}

export async function setCompanyTimeSettings(settings) {
    console.log("Setting time settings: ", settings);
    const companyDocRef = doc(db, 'Kompanier', companyId(), 'details', 'timeSettings');
    await setDoc(companyDocRef, settings);
}

export async function getCompanyTimeSettings() {
    /*const companyDocRef = doc(db, 'Kompanier', companyId(), 'details', 'timeSettings');
    const companyDoc = await getDoc(companyDocRef);
    return companyDoc.data();*/

    const settings = await getFirmSettings("timeSettings");

    return settings;
}

export async function getCompanyTimeSettingsById(company) {
    const companyDocRef = doc(db, 'Kompanier', company, 'details', 'timeSettings');
    const companyDoc = await getDoc(companyDocRef);
    return companyDoc.data();
}



export async function addMembership(userId, companyId) {
    // See if user already has membership with that company
    const q = query(collection(db, 'memberships'), where("userId", "==", userId), where("company", "==", companyId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
        console.log("User already has membership with that company");
        return;
    }

    console.log("Adding membership: ", userId, companyId);
    const membershipData = { userId: userId, company: companyId };
    const membershipsCollection = collection(db, 'memberships');
    const docRef = await addDoc(membershipsCollection, membershipData);
    console.log("Membership written with ID: ", docRef);
}

export async function getCompaniesForUser(userId, onUpdate) {
    const membershipsCollection = collection(db, 'memberships');
    const q = query(membershipsCollection, where("userId", "==", userId));

    //const querySnapshot = await getDocs(q);

    async function getCompanyInformation(querySnapshot) {
        const companies = [];
        console.log("user ", userId, " has ", querySnapshot.docs.length, " companies")
        for (const documents of querySnapshot.docs) {
            const company = documents.data().company;
            //console.log("Company ID: ", company);
            //const companyDocRef = doc(db, 'companies', companyId);
            const id = company.id || company.companyId || company;
            //console.log("Company ID: ", id);
            //console.log("Company ID: ", id);
            const companyDoc = await getDoc(doc(db, 'Kompanier', id));
            //console.log("Company: ", companyDoc);
            //const companyDoc = await getDoc(db, 'companies', companyId);
            //console.log("Company: ", companyDoc.data());
            companies.push(companyDoc.data());
        }
        //console.log("Companies for user: ", companies);
        return companies;
    }

    console.log("getting Company Information");

    // Update the companies
    onSnapshot(q, async (snapshot) => {
        console.log("getting Company Information2");
        const list = await getCompanyInformation(snapshot)//snapshot.docs.map((doc) => ({ ...doc.data() }));
        //console.log("Companies for user: ", list);
        onUpdate(list);
    });


    return getCompanyInformation(await getDocs(q));
}

export async function sendInvitation(invitation, company, customEmail, newCompany) {
    const functions = getFunctions(app, 'europe-west2');
    const sendInvitationEmail = httpsCallable(functions, 'sendInvitation');
    sendInvitationEmail(
        {
            invitation: invitation,
            company: company,
            customEmail: customEmail,
            newCompany: newCompany
        }
    ).then((result) => {
        console.log(result);
    }).catch((error) => {
        console.log(error);
    });

    return console.info("Invitasjon sent");
}

export async function getInvitations(user, onUpdate) {


    if (!user) {
        throw new Error('No authenticated user found');
    }

    const email = user.email;
    const uid = user.uid;

    console.log("Getting invitations for: ", email, uid);

    const q = query(collection(db, 'invitasjoner'), where("email", "==", email), where("accepted", "==", false));

    // Initial fetch of invitations
    const querySnapshot = await getDocs(q);
    const invitations = querySnapshot.docs.map(doc => doc.data());

    // Listen to changes in invitations
    const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedInvitations = snapshot.docs.map(doc => doc.data());
        onUpdate(updatedInvitations);
    });

    return invitations;
}




export async function acceptInvitation(userId, invitasjon) {
    if (!invitasjon?.id || invitasjon?.id === undefined) return
    console.log("Accepting invitation: ", userId, invitasjon);
    const functions = getFunctions(app, 'europe-west2');
    const acceptInvitationServer = httpsCallable(functions, 'acceptInvitation');
    const result = await acceptInvitationServer({ userId: userId, invitasjon: invitasjon });
    console.info(result)
    return result;
}

export async function declineInvitation(userId, companyId) {
    const q = query(collection(db, 'users', userId, 'invitations'), where("companyId", "==", companyId));
    const querySnapshot = await getDocs(q);
    const invitationDoc = querySnapshot.docs[0];
    await deleteDoc(invitationDoc.ref);
}

export async function kickUser(userId) {
    const functions = getFunctions(app, 'europe-west2');
    const kickUser = httpsCallable(functions, 'removeUser');
    const result = await kickUser({ userId: userId, companyId: companyId() });
    console.log(result);
    if (result?.data?.error) console.error(result.data.error);
    return result;
}

export async function createInvitationLink(data, company) {
    const functions = getFunctions(app, 'europe-west2');
    const createInvitationLink = httpsCallable(functions, 'createInviteLink');
    const result = await createInvitationLink({ invitation: data, company: company });
    console.log(result.data.result);
    return result.data.link;
}

export async function getThisCompany(onUpdate) {
    const companyDocRef = doc(db, 'Kompanier', companyId());
    const companyDoc = await getDoc(companyDocRef);


    if (onUpdate) {
        const unsubscribe = onSnapshot(companyDocRef, (snapshot) => {
            onUpdate(snapshot.data())
        });
    }

    return companyDoc.data();
}

export async function getCompany(companyId) {
    const companyDocRef = doc(db, 'Kompanier', companyId);
    const companyDoc = await getDoc(companyDocRef);
    return companyDoc.data();
}







/*
export async function updateGame(gameId, update) {
    const updateObj = Object.assign({}, update) // Delete Custom object/class
    await updateDoc(doc(db, 'Games', gameId), updateObj);
}*/

export async function setReportFirebase(data) {
    console.log(data)
    let dataCopy = Object.assign({}, data, { lastUpdated: serverTimestamp() }) // Delete Custom object/class});
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents", dataCopy.id.toString());

    try {
        await setDoc(reportCol, dataCopy);
        console.log('Document successfully written!');
    } catch (error) {
        console.error('Klarte ikke å oppdatere rapporten i databasen. Se om du har nett eller restart appen. \n' + JSON.stringify(error), JSON.stringify(error));
    }
}

export async function deleteReport(document) {
    console.log(document)
    console.log(document.status)
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'needs-review', "documents", document.id.toString());
    const secondReportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents", document.id.toString());
    const reportDeleteCol = collection(db, 'Kompanier', companyId(), 'rapporter', 'deleted', "documents");
    // If the report is in needs-review, delete it from there

    const reportColExists = await getDoc(reportCol);
    const secondReportColExists = await getDoc(secondReportCol);

    if (reportColExists.exists()) {
        console.log("ReportCol exists")
        try {
            await addDoc(reportDeleteCol, document);
        } catch (error) {
            console.error('couldn\'t setDoc reportDeleteCol')
            console.error(error)
        }
        try {
            console.log("got to setDoc reportDeleteCol")
            await deleteDoc(reportCol);
        } catch (error) {
            console.error('couldn\'t deleteDoc reportCol')
            console.error(error)
        }
        console.log("got to deleteDoc reportCol")
    }
    // If the report is in not-sent, delete it from there
    if (secondReportColExists.exists()) {
        console.log(secondReportColExists)
        console.log(secondReportColExists.data())
        try {
            console.log("Needs review exists")
            await addDoc(reportDeleteCol, document);
        } catch (error) {
            console.error('couldn\'t setDoc reportDeleteCol')
            console.error(error)
        }
        try {
            console.log("got to setDoc reportDeleteCol")
            await deleteDoc(secondReportCol);
        } catch (error) {
            console.error('couldn\'t deleteDoc secondReportCol')
            console.error(error)
        }
        console.log("got to deleteDoc secondReportCol")
    }
}

export async function sendReportAndEmail(report) {

    const functions = getFunctions(app, 'europe-west2');
    const sendReportEmail = httpsCallable(functions, "sendReportEmailNew");
    try {
        console.log({ report: report, companyId: companyId() })
        const result = await sendReportEmail({ report: report, companyId: companyId() });
        console.log(result);
        return result;
    } catch (error) {
        console.error(error);
        return error;
    }
}



export async function updateReviewReport(data) {
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'needs-review', "documents", data.id.toString());

    try {
        await setDoc(reportCol, data);
        console.log('Document successfully written!');
    } catch (error) {
        console.error('Klarte ikke å oppdatere rapporten i databasen. Se om du har nett eller restart appen. \n' + JSON.stringify(error), JSON.stringify(error));
    }
}

/*export async function getSentReports() {
    const reportCol = collection(db, 'Kompanier', companyId(), 'klienter', 'sent', "documents");
    const reportSnapshot = await getDocs(reportCol);
    const reportList = reportSnapshot.docs.map(doc => doc.data());
    return reportList
    /*const reportCol = collection(db, 'Reports');
    const reportSnapshot = await getDocs(reportCol);
    const reportList = reportSnapshot.docs.map(doc => doc.data());
    return reportList
}*/

export async function getNeedsReviewReports(onUpdate) {
    const reportCol = collection(db, 'Kompanier', companyId(), 'rapporter', 'needs-review', "documents");
    const reportSnapshot = await getDocs(reportCol);
    const reportList = reportSnapshot.docs.map(doc => doc.data());

    const q = query(reportCol);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            onUpdate(querySnapshot)
            //console.warn("Updated");
        });
    }

    return reportList
}

export async function getNotSentReports(onUpdate) {
    const reportCol = collection(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents");
    const reportSnapshot = await getDocs(reportCol);
    const reportList = reportSnapshot.docs.map(doc => doc.data());

    const q = query(reportCol);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            onUpdate(querySnapshot)
            //console.warn("Updated");
        });
    }

    return reportList
}

export async function moveReportToNeedsReview(report) {
    console.log(report)
    // Move to Kompanier/companyId/klienter/needs-review/documents
    const reportCol2 = doc(db, 'Kompanier', companyId(), 'rapporter', 'needs-review', "documents", report.id.toString());
    await setDoc(reportCol2, report);

    // Delete from Kompanier/companyId/klienter/not-sent/documents
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents", report.id.toString());
    await deleteDoc(reportCol);
}

export async function getReportsThatNeedsReview(onUpdate) {
    const reportCol = collection(db, 'Kompanier', companyId(), 'rapporter', 'needs-review', "documents");
    const q = query(reportCol, orderBy("dato", "desc"));
    const reportSnapshot = await getDocs(q);
    const reportList = reportSnapshot.docs.map(doc => doc.data());

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const reportList = querySnapshot.docs.map(doc => doc.data());
            onUpdate(reportList)
        });
    }

    return reportList
}

export async function setReportNumber(number) {
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent');
    await setDoc(reportCol, { number: number });
}

export async function getReportNumber() {
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent');
    const reportSnapshot = await getDoc(reportCol);
    const reportList = reportSnapshot.data();
    console.log(reportList)
    return reportList
}

// Move report to sent folder
export async function moveReportToSent(report) {
    console.log(report)
    // Move to Kompanier/companyId/klienter/sent/documents
    const reportCol2 = doc(db, 'Kompanier', companyId(), "prosjekter", (report?.project?.id || "noProject"), "documents", report.id.toString());
    await setDoc(reportCol2, report);

    if (report?.status === "needToBeAccepted") {
        const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'needs-review', "documents", report.id.toString());
        await deleteDoc(reportCol);
    } else {

        const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents", report.id.toString());
        await deleteDoc(reportCol);
    }
}

//Get all clients from firebase, then all projects from each client
export async function getClientsAndProjectsInOne() {
    const [klienter, projects] = await Promise.all([getClients(), getProjects()]);


    const klienterWithProjects = klienter.map((klient) => {
        const klientProjects = projects.filter((project) => project.firmaConnection === klient.id)
        return { ...klient, projects: klientProjects }
    }
    )
    let projectsWithoutClient = projects.filter((project) => !project.firmaConnection).map((project) => ({ projects: [project], id: createId(), navn: null }))

    const klienterAndProjectsWithProjects = klienterWithProjects.concat(projectsWithoutClient)

    return klienterAndProjectsWithProjects.filter((klient) => klient)
}

/*export async function getClientsAndProjectsInOne() {
    const klienter = await getClients()

    const klientsWithProjects = await Promise.all(klienter.map(async (klient) => {
        console.log(klient)
        if (!klient.id) return
        console.log(klient)
        const projects = await getProjectsByCompanyId(klient)
        return { ...klient, projects: projects }
    }))

    return klientsWithProjects.filter((klient) => klient)
}*/




// Get all reports from firebase with the project name sorted by date
export async function getReportsByProjectName(projectName) {
    const reportCol = collection(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents");
    const q = query(reportCol, where("project", "==", projectName), orderBy("dato", "desc"));
    const reportSnapshot = await getDocs(q);
    const reportList = reportSnapshot.docs.map(doc => doc.data());
    return reportList
}

export async function getSentReportsbyProject(project, onUpdate) {
    //console.log(firma)
    //console.log(project)
    if (!project) return []
    const projectName = project.id;
    const reportCol = collection(db, 'Kompanier', companyId(), "prosjekter", projectName, "documents");
    const q = query(reportCol, orderBy("dato", "desc"));
    const reportSnapshot = await getDocs(q);
    const reportList = reportSnapshot.docs.map(doc => doc.data());

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const reportList = querySnapshot.docs.map(doc => doc.data());
            onUpdate(reportList)
        }
        );
    }

    return reportList
}

export async function getProjectsByCompanyId(firma, onUpdate) {
    const projectCol = collection(db, 'Kompanier', companyId(), "prosjekter");
    const projectSnapshot = await getDocs(projectCol);

    const q = query(projectCol);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map(doc => doc.data());
            const projects = data.filter((project) => project.firmaConnection === firma.id)
            onUpdate(projects)
            //console.warn("Updated");
        });
    }

}

/*
export async function getProjectsByCompanyId(firma, onUpdate) {
    const projectCol = collection(db, 'Kompanier', companyId(), 'klienter', firma.id, "projekter");
    const projectSnapshot = await getDocs(projectCol);
    const projectList = projectSnapshot.docs.map(doc => doc.data());


    const q = query(projectCol);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            onUpdate(querySnapshot)
            //console.warn("Updated");
        });
    }

    console.log(projectList)
    return projectList
}
*/

export async function getDocumentsByNoProjectId(firma, onUpdate) {
    console.log(firma)
    const projectCol = collection(db, 'Kompanier', companyId(), "prosjekter", "noProject", "documents");
    const projectSnapshot = await getDocs(projectCol);
    const projectList = projectSnapshot.docs.map(doc => doc.data());

    const q = query(projectCol);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            onUpdate(querySnapshot)
            //console.warn("Updated");
        });
    }

    console.log(projectList)
    return projectList
}

export async function createProject(prosjekt) {
    const projectCol = doc(db, 'Kompanier', companyId(), "prosjekter", prosjekt.id);
    await setDoc(projectCol, prosjekt);
}

export async function getProjects(onUpdate) {
    const projectCol = collection(db, 'Kompanier', companyId(), "prosjekter");
    const projectSnapshot = await getDocs(projectCol);
    const projectList = projectSnapshot.docs.map(doc => doc.data());

    const q = query(projectCol);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map(doc => doc.data());

            onUpdate(data)
            //console.warn("Updated");
        });
    }

    return projectList
}

export async function deleteProject(prosjekt) {
    const projectCol = doc(db, 'Kompanier', companyId(), "prosjekter", prosjekt.id);
    await deleteDoc(projectCol);
}

export async function updateProject(prosjekt) {
    const projectCol = doc(db, 'Kompanier', companyId(), "prosjekter", prosjekt.id);
    await setDoc(projectCol, prosjekt);
}

export async function getFileDocuments(project, lastSeen, onUpdate) {
    //doc(db, 'Kompanier', companyId(), 'klienter', firma.id, "projekter", projekt.id, "filer", report.id.toString());
    //const reportCol = collection(db, 'Kompanier', companyId(), klienter, firma.id, "projekter", projectName, "documents");
    // Get all the items based upon last seen to add to the list. lastSeen is a date.

    /*if (lastSeen) {
        const q = query(collection(db, 'Kompanier', companyId(), "prosjekter", project.id, 'filer'), orderBy("dato", "desc"));
        const querySnapshot = await getDocs(q);
        const list = querySnapshot.docs.map((doc) => ({ ...doc.data() }));

        return list
    }*/

    // Just get all items if no lastSeen
    const q = query(collection(db, 'Kompanier', companyId(), "prosjekter", project.id, 'filer'));
    const querySnapshot = await getDocs(q);
    const list = querySnapshot.docs.map((doc) => ({ ...doc.data() }));
    return list
}

export async function addFilesDocuments(project, fileObj) {
    const fileCol = doc(db, 'Kompanier', companyId(), "prosjekter", project.id, 'filer', fileObj.id);
    await setDoc(fileCol, fileObj);
}

export async function deleteFileDocument(project, fileObj) {
    const fileCol = doc(db, 'Kompanier', companyId(), "prosjekter", project.id, 'filer', fileObj.id);
    await deleteDoc(fileCol);
}

export async function getReportById(id) {
    const reportCol = doc(db, 'Kompanier', companyId(), 'rapporter', 'not-sent', "documents", id.toString());
    const reportSnapshot = await getDoc(reportCol);
    const reportList = reportSnapshot.data();
    return reportList
}

export async function setClientFirebase(data) {
    console.log(data)
    const clientCol = doc(db, 'Kompanier', companyId(), 'klienter', data.id || randomId());
    await setDoc(clientCol, data);
}

export async function getClients(onUpdate) {
    const clientCol = collection(db, 'Kompanier', companyId(), 'klienter');
    const clientSnapshot = await getDocs(clientCol);
    const clientList = clientSnapshot.docs.map(doc => doc.data());

    const q = query(clientCol);
    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            onUpdate(querySnapshot)
            //console.warn("Updated");
        });
    }

    return clientList
}

export async function getClientById(id, onUpdate) {
    console.log(id);

    // Getting the document reference
    const clientDocRef = doc(db, 'Kompanier', companyId(), 'klienter', id);

    // Fetching the document data
    const clientSnapshot = await getDoc(clientDocRef);
    const clientData = clientSnapshot.data();
    console.log(clientData);

    // Setting up the listener if onUpdate is provided
    let unsubscribe;
    if (onUpdate) {
        unsubscribe = onSnapshot(clientDocRef, (docSnapshot) => {
            console.log(docSnapshot);

            if (docSnapshot.exists()) {
                const updatedData = docSnapshot.data();
                console.log(updatedData);

                onUpdate(updatedData);
            }
        });
    }

    // You can also return the unsubscribe function if needed
    return {
        data: clientData,
        unsubscribe
    };
}

export async function setUtstyr(obj) {
    console.log(obj)
    const utstyrCol = doc(db, 'Kompanier', companyId(), 'utstyr', obj.id || randomId());
    await setDoc(utstyrCol, obj);
}

export async function getUtstyr(onUpdate) {
    const utstyrCol = collection(db, 'Kompanier', companyId(), 'utstyr');
    const utstyrSnapshot = await getDocs(utstyrCol);
    const utstyrList = utstyrSnapshot.docs.map(doc => doc.data());

    const q = query(utstyrCol);
    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            onUpdate(querySnapshot)
            //console.warn("Updated");
        });
    }

    return utstyrList
}

export async function deleteUtstyr(id) {
    const utstyrCol = doc(db, 'Kompanier', companyId(), 'utstyr', id);
    await deleteDoc(utstyrCol);
}


export async function handleUpload(file, folderName, fileName) {
    const fileType = file.type.split('/')[0];
    //if (file?.size > 5000000) return { type: "error", msg: "File Too Big" }//window.ui.setState({ otherUI: ["test"] })
    if (fileType !== "image" && fileType !== "video") return { type: "error", msg: "File Type Not Supported" }
    const storage = getStorage();
    const id = folderName && fileName ? `${companyId() + "/" + folderName + "/"} ${fileName}` : `bart${Math.floor(Math.random() * 10000000000000)}`
    const storageRef = ref(storage, id || "test.png");

    // 'file' comes from the Blob or File API
    const ImageUrl = await uploadBytes(storageRef, file).then(async (snapshot) => {
        console.log('Uploaded a blob or file!');
        const url = await getDownloadURL(storageRef)
        return url
    })
    return ImageUrl
}

export async function handleUploadPdf(file, folderName, fileName) {
    //const fileType = file.type.split('/')[0];
    //if (file?.size > 5000000) return { type: "error", msg: "File Too Big" }//window.ui.setState({ otherUI: ["test"] })
    //if (fileType !== "application") return { type: "error", msg: "File Type Not Supported" }
    const storage = getStorage();
    const id = folderName && fileName ? `${companyId() + "/" + folderName + "/"} ${fileName}` : `bart${Math.floor(Math.random() * 10000000000000)}`
    const storageRef = ref(storage, id || "test.png");

    // 'file' comes from the Blob or File API
    const ImageUrl = await uploadBytes(storageRef, file).then(async (snapshot) => {
        console.log('Uploaded a blob or file!');
        const url = await getDownloadURL(storageRef)
        return url
    })
    return ImageUrl
}

export async function handleUploadFile(file, folderName, fileName) {
    //const fileType = file.type.split('/')[0];
    if (file?.size > 5000000) return console.error("Filen er for stor til å bli lastet opp")//window.ui.setState({ otherUI: ["test"] })
    //if (fileType !== "application") return { type: "error", msg: "File Type Not Supported" }
    const storage = getStorage();
    const id = folderName && fileName ? `${companyId() + "/" + folderName + "/"} ${fileName}` : `bart${Math.floor(Math.random() * 10000000000000)}`
    const storageRef = ref(storage, id || "test.png");

    // 'file' comes from the Blob or File API
    const ImageUrl = await uploadBytes(storageRef, file).then(async (snapshot) => {
        console.log('Uploaded a blob or file!');
        const url = await getDownloadURL(storageRef)
        return url
    })
    return ImageUrl
}

export async function uploadImageToFolder(folderName, data) {
    let folder = folderName;
    if (!folderName) {
        folder = "general"
    }

    //const tokensCol = collection(db, 'Galleri', folderName);
    //const tokensCol = doc(db, 'Galleri', folder);
    // Needs update - Might need to add something in-between or switch from collection to doc
    const tokensCol = collection(db, companyId(), 'filer', folder);
    const docRef = await addDoc(tokensCol, data);
    console.log("Document written with ID: ", docRef.id);
}

export async function updateFolderLength(folderName, i, lastImage) {
    const oldLength = await getFolderLength(folderName);
    const folderCol = doc(db, companyId(), 'filer', folderName);
    await setDoc(folderCol, {
        folder: true,
        image: lastImage,
        length: oldLength + i,
        name: folderName,
    });
}


export async function getFolderLength(folderName) {
    const folderCol = doc(db, companyId(), 'filer', folderName);
    let test = await getDoc(folderCol)
    const folderLength = test?.data()?.length || 0;
    return folderLength;
}

export async function getFolderImages(folderName) {
    let folderCol
    if (folderName?.length > 0) {
        folderCol = collection(db, companyId(), 'filer', folderName, 'images');
    } else {
        folderCol = collection(db, companyId(), 'filer');
    }

    const folderSnapshot = await getDocs(folderCol);
    const folderList = folderSnapshot.docs.map(doc => doc.data());
    return folderList
    /*const q = query(folderCol, orderBy("time", "desc"), limit ? limit : 100);
    const folderSnapshot = await getDocs(q);
    const folderList = folderSnapshot.docs.map(doc => doc.data());
    return folderList;*/
}


function randomId() {
    return Math.random().toString(36).substring(2);
}






//Mal

export async function createMal(mal) {
    const malRef = doc(db, "Kompanier", companyId(), 'mal', mal.id);

    try {
        await setDoc(malRef, {
            ...mal,
            timestamp: serverTimestamp(),
        });
        console.log("Document created")
    } catch (error) {
        console.error('Error creating mal:', error);
    }
}


export async function getMal(id, onUpdate) {
    const malCol = collection(db, "Kompanier", companyId(), 'mal');
    const q = query(malCol, where("id", "==", id));

    const unsubscribe = onSnapshot(q, (snapshot) => {
        const malList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        onUpdate(malList);
    });

    console.log("getMal", id)

    // Return the unsubscribe function
    return () => {
        unsubscribe();
    };
}

export async function getMals(onUpdate) {
    console.log("getMals")
    console.log("getMals", companyId())
    console.log("getMals", db)
    if (!companyId()) getCompanyId()
    const malCol = collection(db, "Kompanier", companyId(), 'mal');
    const q = query(malCol, orderBy("timestamp", "desc"), limit(25));

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const malList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            console.log("getMals Subscribed")
            onUpdate(malList);
        });

        return () => {
            unsubscribe();
        };
    } else {
        const snapshot = await getDocs(q);
        const malList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        return malList
    }
}

export async function updateMal(id, mal) {
    const malRef = doc(db, "Kompanier", companyId(), 'mal', id);

    console.log("UpdateMal")

    await updateDoc(malRef, {
        ...mal,
        timestamp: serverTimestamp(),
    });
}

export async function deleteMal(id) {
    const malRef = doc(db, "Kompanier", companyId(), 'mal', id);
    await deleteDoc(malRef);
}






// Notater

export const updateNote = async (noteContent, user) => {
    console.log("updateNote")
    console.log(noteContent)
    console.log(user)
    try {
        //const notesCollection = doc(db, "Kompanier", companyId(), "notater", user.id, "documents", noteContent.id);
        const noteDocument = doc(db, "Kompanier", companyId(), "notater", user.id, "documents", noteContent.id);
        await setDoc(noteDocument, {
            ...noteContent,
            timestamp: serverTimestamp(),
        });
    } catch (error) {
        console.error("Error updating note: ", error);
    }
};

export const getNote = async (id, companyUser) => {
    try {
        //const notesCollection = doc(db, "Kompanier", companyId(), "notater", companyUser.id);
        const noteDocument = doc(db, "Kompanier", companyId(), "notater", companyUser.id, "documents", id);
        const note = await getDoc(noteDocument);
        return note.data();
    } catch (error) {
        console.error("Error getting note: ", error);
        return "";
    }
};


export const getNotes = async (onUpdate, user) => {
    try {
        const notesCollection = collection(db, "Kompanier", companyId(), "notater", user.id, "documents");
        const q = query(notesCollection, orderBy("timestamp", "desc"), limit(25));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const noteList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            onUpdate(noteList);
        }
        );

        /*const notesCollection = collection(db, "Kompanier", companyId(), "notater", user.id);
        const q = query(notesCollection, orderBy("timestamp", "desc"), limit(25));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const noteList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            onUpdate(noteList);
        }
        );*/

        // Return the unsubscribe function
        return () => {
            unsubscribe();
        }
    } catch (error) {
        console.error("Error getting notes: ", error);
        return "";
    }
};



// Chat //chat

//remove later
export async function getMessages(setMessages) {
    if (!companyId()) return console.log("No company id")
    //const messagesCol = doc(db, "Kompanier", companyId(), "messages", "group1");
    /*const messagesCol = collection(db, companyId(), "messages", "group1");
    const q = query(messagesCol, orderBy("timestamp", "desc"), limit(25));


    const unsubscribe = onSnapshot(q, (snapshot) => {
        const messageList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        localStorage.setItem("messages", JSON.stringify(messageList));
        setMessages(messageList);
    });*/

    // Return the unsubscribe function
    return () => {
        //unsubscribe();
    };
}

export async function getChats(onUpdate, onUpdateRoles, companyUser) {
    //const user = await getAuthUser();
    //Get all chats in company which chat.users contains user.uid, and all chats where chat.roles contains user.roles.id
    console.log("getChats")
    console.log(companyUser)
    try {
        /*const chatCol = collection(db, "Kompanier", companyId(), "chats");
        */

        const chatCol = collection(db, "Kompanier", companyId(), "chats");
        const q = query(chatCol, where("brukere", "array-contains", companyUser.id));
        const q2 = query(chatCol, where("roles", "array-contains-any", companyUser.roleIds));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const chatList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            console.log(chatList)
            onUpdate(chatList);
        }
        );

        if (!companyUser?.roleIds) return () => { unsubscribe(); }
        const unsubscribe2 = onSnapshot(q2, (snapshot) => {
            const chatList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            console.log(chatList)
            onUpdateRoles(chatList);
        }
        );

        console.log("getChats Subscribed")

        // Return the unsubscribe function
        return () => {
            unsubscribe();
            unsubscribe2();
        };
    } catch (error) {
        console.error("Error getting chats: ", error);
        return "";
    }
}

export async function createChat(chat) {
    //add doc and make id of chat the same as the doc id
    const chatRef = collection(db, "Kompanier", companyId(), "chats");
    console.log("CreateChat")
    await setDoc(doc(chatRef, chat.id), {
        ...chat,
        timestamp: serverTimestamp(),
    });
}

export async function updateChat(id, chat) {
    const chatRef = collection(db, "Kompanier", companyId(), "chats");

    console.log("UpdateChat")

    await updateDoc(chatRef, {
        ...chat,
        timestamp: serverTimestamp(),
    });
}

export async function deleteChat(id) {
    const chatRef = doc(db, companyId(), "chat", id);
    await deleteDoc(chatRef);
}

export async function getChatMessages(chatId, onUpdate) {
    console.log("getChatMessages")
    const chatCol = collection(db, "Kompanier", companyId(), "chats", chatId, "messages");
    const q = query(chatCol, orderBy("timestamp", "desc"), limit(50));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log("getChatMessages Subscribed")
        let chatList = [];
        querySnapshot.forEach((doc) => {
            chatList.push(doc.data());
        });
        onUpdate(chatList);
    }
    );

    // Return the unsubscribe function
    return () => {
        unsubscribe();
    };
}

export async function sendMessage(chatId, message) {
    console.log("Kompanier", companyId(), "chats", chatId, "messages", message.id)
    const chatRef = doc(db, "Kompanier", companyId(), "chats", chatId, "messages", message.id);

    try {
        await setDoc(chatRef, {
            ...message,
            timestamp: serverTimestamp(),
        });
        console.log("Message sent")
    } catch (error) {
        console.error('Error sending message:', error);
    }
}





// Authentication

/*export async function checkIfAuthenticated() {
    return new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            console.log(user);
            unsubscribe(); // Unsubscribe from the listener after getting the user state
            resolve(user !== null);
        });
    });
}

export async function athentication(authEndFunction) {
    const provider = new GoogleAuthProvider();

    try {
        await signInWithRedirect(auth, provider);
    } catch (error) {
        console.error(error);
        authEndFunction(null);
    }
}*/
export async function athentication(authEndFunction) {
    console.log("athentication")
    if (Capacitor.isNativePlatform()) {
        try {
            const user = await FirebaseAuthentication.signInWithGoogle();
            // User is authenticated, handle the user
            // 2. Sign in on the web layer using the id token
            const credential = GoogleAuthProvider.credential(user.credential?.idToken);
            const auth = getAuth();
            await signInWithCredential(auth, credential);


            await handleAuthUser(authEndFunction, user);
        } catch (error) {
            console.error(error);
            authEndFunction(null);
        }
    } else {
        // Check if redirect result exists, if not initiate sign in with redirect
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(getAuth(), provider);
            console.log("athentication", result)
            if (result?.user) {
                // User just returned from the redirect, handle the user
                await handleAuthUser(authEndFunction);
            } else {
                await signInWithRedirect(auth, provider);
            }
        } catch (error) {
            console.error(error);
            authEndFunction(null);
        }
    }
}


async function handleAuthUser(authEndFunction, userTTT) {
    console.log("handleAuthUser")
    console.log("handleAuthUser", userTTT)
    //TODO make email into id / UID
    localStorage.setItem("user", userTTT.uid);
    localStorage.setItem("userId", userTTT.uid);
    authEndFunction(userTTT.user);
    return

    const user = userTTT?.user || auth.currentUser;
    localStorage.setItem("user", user.uid);

    const q = query(collection(db, "users"), where("email", "==", user.email));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
        await setDoc(doc(db, "users", user.uid), {
            id: user.uid,
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
            photoUrl: "https://firebasestorage.googleapis.com/v0/b/strugglemeals-85a72.appspot.com/o/randomPerson.png?alt=media&token=69068027-acbd-41f5-b95b-6dd026dc250c",
            registered: true
        });
    } else {
        docs.docs.forEach((docToData) => {
            const data = docToData.data();
            console.log(docToData.data());
            if (!data.registered) {
                updateDoc(doc(db, "users", data.id), {
                    id: data.id,
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "google",
                    email: user.email,
                    photoUrl: "https://firebasestorage.googleapis.com/v0/b/strugglemeals-85a72.appspot.com/o/randomPerson.png?alt=media&token=69068027-acbd-41f5-b95b-6dd026dc250c",
                    registered: true
                });
            }
        });
    }


    authEndFunction(auth.currentUser);
    //window.location.reload();
}

export async function checkIfAuthenticated() {
    /*const oldToken = localStorage.getItem("idToken");
    console.log(oldToken);
    if (oldToken) {
        return true;
    }*/
    return true

    return new Promise(async (resolve) => {
        if (Capacitor.isNativePlatform()) {
            let attempts = 0;
            const maxAttempts = 10; // Maximum number of attempts

            // Check the current user
            const checkCurrentUser = async () => {
                attempts++;
                if (attempts > maxAttempts) {
                    resolve(null);
                    return;
                }

                const result = await FirebaseAuthentication.getCurrentUser();
                console.log(result);
                if (result.user) {
                    const tokenResult = await FirebaseAuthentication.getIdToken();
                    localStorage.setItem("idToken", tokenResult.token);
                    console.log(result);
                    localStorage.setItem('userId', result.uid);
                    resolve(result.user !== null);
                } else if (auth.currentUser) {
                    const idToken = await auth.currentUser.getIdTokenResult();
                    localStorage.setItem("idToken", idToken.token);
                    resolve(true);
                } else {
                    setTimeout(checkCurrentUser, 2000); // Check again after 1 second
                }
            }

            checkCurrentUser();
        } else {
            //console.log(auth.currentUser)
            // Fallback to web-based authentication using Firebase JavaScript SDK
            // Check if the user is authenticated
            console.log("checkIfAuthenticated")
            const unsubscribe = onAuthStateChanged(await auth, async (user) => {
                //console.log(user);
                unsubscribe();
                //console.log(user);
                if (user) {
                    //const idToken = await FirebaseAuthentication.getIdToken();
                    const idToken = await user.getIdTokenResult();
                    localStorage.setItem("idToken", idToken.result);
                } else {
                    localStorage.removeItem("idToken");
                }
                //console.log(user !== null);
                resolve(user !== null);
            });
        }
    });
}

export async function signInWithEmailAndPasswordInFirebase(email, password) {
    try {
        if (!Capacitor.isNativePlatform()) {

            console.log("Using web platform for authentication");
            //console.log(email, password)
            await signInWithEmailAndPassword(getAuth(), email, password);
            //console.log("got here in web")
            //const user = await getAuthUser();

            //if (!user) throw new Error("User not authenticated after sign-in");
            //return await handleAuthUser(() => { }, user);
            return true
        } else {

            console.log("signInWithEmailAndPasswordInFirebase - native platform");
            const result = await FirebaseAuthentication.signInWithEmailAndPassword({
                email: email,
                password: password
            });

            const credential = EmailAuthProvider.credential(email, password);
            console.log("emailCredential auth ");
            const auth = getAuth();
            console.log("emailCredential login ");
            await signInWithCredential(auth, credential);
            console.log(result);
            console.log("Returning result from signInWithEmailAndPasswordInFirebase")
            /*if (result.user) {
                // User is authenticated, handle the user
                await handleAuthUser(() => { }, result.user);
            }*/
            localStorage.setItem("userId", result.user.uid);

            return result;

        }
    } catch (error) {
        console.error("Error during signInWithEmailAndPasswordInFirebase:", error);
        throw new Error("Wasn't able to log in.")
        return new Error("Wasn't able to log in.")
    }
};

export const creatUser = async (user) => {
    const functions = getFunctions(app, 'europe-west2');
    const onCreateUser = httpsCallable(functions, 'onCreateUser');
    await onCreateUser(user);
}


export const signUpWithEmailAndPassword = async (email, password, name) => {
    const auth = getAuth(); // Initialize auth, make sure Firebase is initialized before calling this
    try {
        // Creating user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        if (userCredential.user) {
            // Update the user's profile with the name
            /*await updateProfile(userCredential.user, {
                displayName: name
            });*/
            //console.log(userCredential);
            // Now, call your handleAuthUser function, or do something else with the authenticated user
            //const user = userCredential; // Get the authenticated user
            await creatUser({...userCredential.user, name: name})
            return await handleAuthUser(() => { }, user); // Assume handleAuthUser is a function you've defined elsewhere
        }
    } catch (error) {
        console.error('Error during sign-up:', error);
        throw error; // Re-throw the error after logging it, or handle it as appropriate
    }
};

// Timetracking

export async function uploadTimeToDb(time,) {
    /*const functions = getFunctions(app, 'europe-west2');
    const uploadTime = httpsCallable(functions, 'uploadTime');

    try {
        const result = await uploadTime({ time: time, companyId: companyId(), userId: await getAuthUserId() });
        console.log(result.data.message);
        return result.data;
    } catch (error) {
        console.error('Error calling uploadTime:', error);
        throw error;
    }*/
    const functions = getFunctions(app, 'europe-west2');
    const gjennomforTime = httpsCallable(functions, 'gjennomfortTimeOverride');
    await gjennomforTime({ time: time, companyId: companyId(), userId: store.getState().user?.user.uid || await getAuthUserId() })
}

export async function uploadTimeToDbRS({ data }) {
    /*
    console.log("setting data DB", data);
    const docRef = doc(db, 'Kompanier', companyId(), 'tid', store.getState().user.user.uid, 'timer', data.id);
    await setDoc(docRef, data);
    */
    
    
    const functions = getFunctions(app, 'europe-west2');
    const gjennomforTime = httpsCallable(functions, 'gjennomfortTimeOverride');
    await gjennomforTime({ time: data, companyId: companyId(), userId: store.getState().user?.user.uid || await getAuthUserId() })
    
}

export async function updateTimesInFirebase(timesInWeek, companyId, weekNumber) {
    const functions = getFunctions(app, 'europe-west2');
    const updateTimesInWeek = httpsCallable(functions, 'updateTimesInWeek');
    console.log(timesInWeek);
    try {
        const result = await updateTimesInWeek({ timesInWeek: { times: timesInWeek }, companyId, userId: store.getState().user?.user.uid || await getAuthUserId() });
        console.log(result.data.message);
        return result.data;
    } catch (error) {
        console.error('Error calling updateTimesInWeek:', error);
        throw error;
    }
}

export async function getRecordedTimeFromDB(onUpdate) {
    // Only 20 records are fetched at a time from the database. If you want more you need to use pagination
    // Order by startDate
    const userId = store.getState().user?.user.uid || await getAuthUserId();
    console.log("Kompanier", companyId(), "tid", userId, "timer")
    const q = query(collection(db, "Kompanier", companyId(), "tid", userId, "timer"), orderBy("startDate", "desc"), limit(20));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const time = [];
        querySnapshot.forEach((doc) => {
            time.push(doc.data());
        });
        onUpdate(time);
    }
    );
}

export async function getRecordedTimeFromDBbyUserId(userId, from, to, onUpdate) {
    // Featch all recording between from and to by userId
    // Order by startDate
    const q = query(collection(db, "Kompanier", companyId(), "tid", userId, "timer"), where("startDate", ">=", from), where("startDate", "<=", to), orderBy("startDate", "desc"));
    // return the results
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const time = [];
        querySnapshot.forEach((doc) => {
            time.push(doc.data());
        });

        if (onUpdate) {
            onUpdate(time, userId);
        }
    }
    );

    //return results
    const documents = await getDocs(q);
    const time = [];
    documents.forEach((doc) => {
        time.push(doc.data());
    });
    return time;
}

export async function deleteTimeFromDB(time, user) {
    const userId = user?.id || user?.uid || store.getState().user?.user.uid || await getAuthUserId();
    console.log(time, userId);
    // create document in slettedeTimer, not set.
    await addDoc(collection(db, "Kompanier", companyId(), "tid", userId, "slettedeTimer"), time);

    await deleteDoc(doc(db, "Kompanier", companyId(), "tid", userId, "timer", time.id));
    console.log("Time deleted");
}

export async function getRecordedTimeFromDBByDate(onUpdate, dateStart, dateEnd) {
    //Get all records between the two dates ordered by startDate
    const userId = store.getState().user?.user.uid || await getAuthUserId();
    const q = query(collection(db, "Kompanier", companyId(), "tid", userId, "timer"), where("startDate", ">=", dateStart), where("startDate", "<=", dateEnd), orderBy("startDate", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const time = [];
        querySnapshot.forEach((doc) => {
            time.push(doc.data());
        });
        onUpdate(time);
    }
    );
}

export async function getRecordedTimeOfCurrentDay(date, onUpdate) {
    //Get all records of the current day
    const userId = store.getState().user?.user.uid || await getAuthUserId();

    const startDateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    //const startDateEndOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

    const startDateEndOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    startDateEndOfDay.setMilliseconds(startDateEndOfDay.getMilliseconds() - 1);


    console.log(startDateStartOfDay);
    console.log(startDateEndOfDay);

    const q = query(collection(db, "Kompanier", companyId(), "tid", userId, "timer"), where("startDate", ">=", startDateStartOfDay), where("startDate", "<=", startDateEndOfDay), orderBy("startDate", "desc"));
    const fetchDocuments = await getDocs(q);
    const time = [];
    fetchDocuments.forEach((doc) => {
        time.push(doc.data());
    });
    console.log(time);

    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const time = [];
            querySnapshot.forEach((doc) => {
                time.push(doc.data());
            });
            onUpdate(time);
        });
    }


    return time;
}

export async function getRecordedTimeOfMonth(date, onUpdate) {
    //Get all records of the date's month
    const userId = store.getState().user?.user.uid || await getAuthUserId();

    const startDateStartOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const startDateEndOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const q = query(collection(db, "Kompanier", companyId(), "tid", userId, "timer"), where("startDate", ">=", startDateStartOfMonth), where("startDate", "<=", startDateEndOfMonth), orderBy("startDate", "desc"));
    const fetchDocuments = await getDocs(q);
    const times = [];
    fetchDocuments.forEach((doc) => {
        times.push(doc.data());
    });


    if (onUpdate) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const times = [];
            querySnapshot.forEach((doc) => {
                times.push(doc.data());
            });
            onUpdate(times);
        });
    }


    return times;
}

export async function setTimeListForUser(newTimeList, user, dato) {
    // Parse the dato string to a Date object
    const dateCET = moment.tz(dato, "Europe/Berlin");

    // Format the date part and combine with the time part from startDate and endDate
    const startDateTime = dateCET.format('YYYY-MM-DD') + 'T' + newTimeList.startDate + ':00';
    const endDateTime = dateCET.format('YYYY-MM-DD') + 'T' + newTimeList.endDate + ':00';

    // Parse the combined date and time strings as CET time
    const startDate = moment.tz(startDateTime, "Europe/Berlin").toDate();
    const endDate = moment.tz(endDateTime, "Europe/Berlin").toDate();


    const timelist = {
        ...newTimeList,
        startDate, // Ensure these are Date objects
        endDate,
        dato: dato, // if you still want to keep the 'dato' field
        userUID: user.id,
        user: user,
        id: createId(),
        notificationSent: false,
        // ... rest of the fields
    };

    console.log(timelist);

    await setDoc(doc(db, "Kompanier", companyId(), "tid", "timeliste", "liste", timelist.id), timelist);
}


export async function updateTimeListForUser(newTimeList, user, dato) {
    const functions = getFunctions(app, 'europe-west2');
    const updateTimeList = httpsCallable(functions, 'updatePlannedTime');

    const date = new Date(dato);

    // Extract the date part from the Date object in YYYY-MM-DD format
    const datePart = date.toISOString().split('T')[0];

    console.log(datePart);
    console.log(newTimeList.startDate);
    console.log(newTimeList.endDate);

    // Concatenate the date part with the time part from startDate
    const startDateTime = `${datePart}T${newTimeList.startDate}:00`;
    const endDateTime = `${datePart}T${newTimeList.endDate}:00`;

    // Create Date objects from the concatenated strings
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    const timelist = {
        ...newTimeList,
        startDate,
        endDate,
        dato: date, // if you still want to keep the 'dato' field
        userUID: user.id,
        user: user,
        notificationSent: false,
    };

    const dataToSend = {
        timelist: timelist,
        companyId: companyId(),
    }

    try {
        const result = await updateTimeList(dataToSend);
        console.log(result.data.message);
        return result.data;
    } catch (error) {
        console.error('Error calling updateTimeList:', error);
        throw error;
    }
}

export async function deleteTimeListForUser(timelist) {
    const functions = getFunctions(app, 'europe-west2');
    const deleteTimeList = httpsCallable(functions, 'deletePlannedTime');

    const dataToSend = {
        timelist: timelist,
        companyId: companyId(),
    }

    try {
        const result = await deleteTimeList(dataToSend);
        console.log(result?.data?.message);
        return result?.data;
    } catch (error) {
        console.error('Error calling deleteTimeList:', error);
        throw error;
    }
}

export async function getTimeListForUser(onUpdate, userUID) {
    // Only get times after yesterday
    console.log("getTimeListForUser")
    console.log(new Date(new Date().setDate(new Date().getDate() - 32)))
    const q = query(collection(db, "Kompanier", companyId(), "tid", "timeliste", "liste"), where("userUID", "==", userUID));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const timeList = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            //timeList.push(data);
            if (!data.gjennomført) {
                timeList.push(data);
            }
        });
        onUpdate(timeList, userUID);
    }
    );
}

export async function getTimeListForUserWithRestricitons(onUpdate, userUID, datoFrom, datoTo) {
    // Only get times after yesterday
    const q = query(collection(db, "Kompanier", companyId(), "tid", "timeliste", "liste"), where("userUID", "==", userUID), where("startDate", ">=", datoFrom), where("startDate", "<=", datoTo), orderBy("startDate", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const timeList = [];
        querySnapshot.forEach((doc) => {
            timeList.push(doc.data());
        });

        console.warn("getTimeListForUserWithRestricitons", timeList);
        onUpdate(timeList, userUID);
    }
    );
}

export async function getTimeListForAllUsers(onUpdate, from, to) {
    const q = query(collection(db, "Kompanier", companyId(), "tid", "timeliste", "liste"), where("startDate", ">=", from), where("startDate", "<=", to), orderBy("startDate", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const timeList = [];
        querySnapshot.forEach((doc) => {
            timeList.push(doc.data());
        });
        onUpdate(timeList);
    }
    );
}

// Move to firebase functions
export async function gjennomførtTime(time, user) {
    // Add the time to the user
    /*await uploadTimeToDb(time);

    // Set the time as gjennomført
    await updateDoc(doc(db, "Kompanier", companyId(), "tid", "timeliste", "liste", time.id), {
        ...time,
        gjennomført: true,
        gjennomførtAv: user,
    });*/

    // Check if the time overlaps with other times
    //const checkOverlap = await get(collection(db, "Kompanier", companyId(), "tid", user.id, "timer"), where("startDate", "<=", time.endDate), where("endDate", ">=", time.startDate));
    const checkOverlap = await checkForTimeOverlap(time, user);
    console.log(checkOverlap)
    if (checkOverlap) {
        // There is an overlap
        console.log(checkOverlap)
        console.log("There is an overlap");
        return false;
    }

    const functions = getFunctions(app, 'europe-west2');
    const gjennomforTime = httpsCallable(functions, 'gjennomfortTime');

    gjennomforTime({ time: time, user: user, companyId: companyId() })
        .then((result) => {
            // Handle the result
            console.log("Time overlap checked:", result.data);
        })
        .catch((error) => {
            // Handle errors here
            console.error("Error checking time overlap:", error);
        });
}


export async function gjennomførtTimeIgnoreOverlap(time, user) {
    console.log("gjennomførtTimeIgnoreOverlap")
    console.log(time)
    // Add the time to the user
    /*await uploadTimeToDb(time);

    // Set the time as gjennomført
    await updateDoc(doc(db, "Kompanier", companyId(), "tid", "timeliste", "liste", time.id), {
        ...time,
        gjennomført: true,
        gjennomførtAv: user,
    });*/

    // Check if the time overlaps with other times
    //const checkOverlap = await get(collection(db, "Kompanier", companyId(), "tid", user.id, "timer"), where("startDate", "<=", time.endDate), where("endDate", ">=", time.startDate));
    const functions = getFunctions(app, 'europe-west2');
    const gjennomforTime = httpsCallable(functions, 'gjennomfortTimeOverride');

    if (time.endDate) {
        // Check if the times are on 2 seperate days, if so, split them up, then upload 2 times seperately
        const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
        const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
        console.log(time)
        await gjennomforTime({
            time: {
                ...time,
                startDate: startDate,
                endDate: endDate
            }, user: user, companyId: companyId()
        })
            .then((result) => {
                // Handle the result
                console.log("Time overlap checked:", result.data);
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error checking time overlap:", error);
            });
        /*
        if (startDate.getDate() !== endDate.getDate()) {
            const firstTime = {
                ...time,
                id: createId(),
                endDate: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1, 0, 0, 0, 0)
            };
            const secondTime = {
                ...time,
                id: createId(),
                startDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)
            };

            gjennomforTime({ time: firstTime, user: user, companyId: companyId() })
                .then((result) => {
                    // Handle the result
                    console.log("Time overlap checked:", result.data);
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error checking time overlap:", error);
                });

            gjennomforTime({ time: secondTime, user: user, companyId: companyId() })
                .then((result) => {
                    // Handle the result
                    console.log("Time overlap checked:", result.data);
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error checking time overlap:", error);
                });


        } else {
            gjennomforTime({ time: time, user: user, companyId: companyId() })
                .then((result) => {
                    // Handle the result
                    console.log("Time overlap checked:", result.data);
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error checking time overlap:", error);
                });
        }*/
    }
}

//const gjennomførtTimeCall = httpsCallable('gjennomførtTime');

export async function checkForTimeOverlap(time, user) {
    if (!time.endDate) {
        return false; // No overlap
    }
    const timesOnStartDate = await getRecordsWithinDay(createDateOutOfFirebaseTimestamp(time.startDate), user);
    const timesOnEndDate = await getRecordsWithinDay(createDateOutOfFirebaseTimestamp(time.endDate), user);

    // Combine and remove duplicates
    const allTimes = [...timesOnStartDate, ...timesOnEndDate].reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
    }, {});

    console.log(allTimes);

    for (let key in allTimes) {
        let existingTime = allTimes[key];
        existingTime.startDate = createDateOutOfFirebaseTimestamp(existingTime.startDate);
        // If no endDate in this time, just go next
        if (!existingTime?.endDate) continue;
        existingTime.endDate = createDateOutOfFirebaseTimestamp(existingTime.endDate);

        // Check for overlap
        if (
            (existingTime.startDate <= time.endDate && existingTime.startDate >= time.startDate) ||
            (existingTime.endDate >= time.startDate && existingTime.endDate <= time.endDate) ||
            (existingTime.startDate <= time.startDate && existingTime.endDate >= time.endDate)
        ) {
            console.log("There is an overlap with:", existingTime);
            return existingTime;
        }
    }
    console.log("There is no overlap");
    return false; // No overlap
}

async function getRecordsWithinDay(date, user) {
    // Start 24 hours before the specified date and time
    const startOfPeriod = new Date(date);
    startOfPeriod.setHours(startOfPeriod.getHours() - 12);

    // End 24 hours after the specified date and time
    const endOfPeriod = new Date(date);
    endOfPeriod.setHours(endOfPeriod.getHours() + 12);

    const col = collection(db, "Kompanier", companyId(), "tid", user.uid, "timer"); // Replace with your collection name
    const startDateQuery = query(col, where('startDate', '>=', startOfPeriod), where('startDate', '<=', endOfPeriod));
    const endDateQuery = query(col, where('endDate', '>=', startOfPeriod), where('endDate', '<=', endOfPeriod));

    const startDateDocs = await getDocs(startDateQuery);
    const endDateDocs = await getDocs(endDateQuery);

    const records = new Map();

    startDateDocs.docs.forEach(doc => {
        const data = doc.data();
        records.set(data.id, data); // Assuming 'id' is the field to identify duplicates
    });

    endDateDocs.docs.forEach(doc => {
        const data = doc.data();
        records.set(data.id, data); // This will overwrite any duplicate records with the same 'id'
    });

    return Array.from(records.values());
}




//Security rules
export async function getRoles(onUpdate) {
    // Get an array of the roles in the company
    console.log(companyId())
    const q = query(collection(db, "Kompanier", companyId(), "roles"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const roles = [];
        querySnapshot.forEach((doc) => {
            roles.push(doc.data());
        });
        onUpdate(roles);
    }
    );

    // return roles
    const documents = await getDocs(q);
    const roles = [];
    documents.forEach((doc) => {
        roles.push(doc.data());
    });
    return roles;
}

export async function getRole(roleId, onUpdate) {
    // Get a specific role
    const q = query(collection(db, "Kompanier", companyId(), "roles"), where("id", "==", roleId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const roles = [];
        querySnapshot.forEach((doc) => {
            roles.push(doc.data());
        });
        onUpdate(roles[0]);
    }
    );
}

export async function setRole(role) {
    // Set a specific role
    await setDoc(doc(db, "Kompanier", companyId(), "roles", role.id), role);
}

export async function deleteRole(roleId) {
    // Delete a specific role
    await deleteDoc(doc(db, "Kompanier", companyId(), "roles", roleId));
}

export async function updateAllUsersWithRole(role) {
    // Update all users with a specific role
    console.log("Update all users with role");
    // The roles is an array of objects, with and id and a name
    //TODO CHANGE THIS TO "Invitasjoner" / UserManagement
    const q = query(collection(db, "Kompanier", companyId(), "users"), where("roles", "array-contains", role.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log("Update all users with role2");
        console.log(querySnapshot);
        querySnapshot.forEach((doc) => {
            const user = doc.data();
            const roles = user.roles;
            const index = roles.indexOf(role.id);
            if (index > -1) {
                roles.splice(index, 1);
            }
            roles.push(role);
            console.log("Update all users with role3");
            updateDoc(doc(db, "Kompanier", companyId(), "users", user.id), {
                ...user,
                roles: roles,
            });
        });
    }
    );
}

/*
export default async function athentication(authEndFunction) {
    const googleProvider = new GoogleAuthProvider();
    const user = auth.currentUser
 
    if (user) console.log(user)
    if (user) return authEndFunction(user)
    const signInWithGoogle = async () => {
        const result = await FirebaseAuthentication.signInWithGoogle();
        authEndFunction(result.user)
        return result.user;
    }
    return signInWithGoogle()
}
 
export async function checkIfAuthenticated() {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      const result = await FirebaseAuthentication.getCurrentUser();
      console.log(result);
      return result.user !== null;
    } catch (error) {
      console.error(error);
      return false;
    }
  }*/

/*return new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            console.log(user)
            unsubscribe(); // Unsubscribe from the listener after getting the user state
            resolve(user !== null);
        });
    });*/

export async function logout(logOutEndFunction) {
    try {
        setCompanyId("");
        console.log("Logging out firebaseAuthentication");
        // Re-initialize the Firebase app


        const currentUser = await FirebaseAuthentication.getCurrentUser();
        if (currentUser?.user) {
            await FirebaseAuthentication.signOut();
        }

        console.log("Logging out firebase", auth.currentUser);
        if (auth?.currentUser) {
            await signOut(auth);
        }


        localStorage.setItem("loggedIn", "false");
        localStorage.removeItem(`${companyId()}User`);
        localStorage.removeItem("idToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("companyId");
        localStorage.removeItem("user");
        localStorage.removeItem("userToDist");
        localStorage.removeItem("fcm");

        if (logOutEndFunction) logOutEndFunction();
    } catch (err) {
        console.log(err);
        alert(err.message);
    }
}

export const firebaseLogout = logout;

export const deleteUserDB = async (user) => {
    const functions = getFunctions(app, 'europe-west2');
    const deleteUserInServer = httpsCallable(functions, 'deleteUser');

    try {
        const result = await deleteUserInServer({ user: user });
        return result.data;
    } catch (error) {
        console.error('Error calling createCompany:', error);
        throw error;
    }
}



/*export const handleLogin = async (navigateToNewPage) => {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle();
    // 2. Sign in on the web layer using the id token
    console.log("Login done")
    console.log(result.user, result.user.credential?.idToken)
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    console.log("Credential")
    console.log(credential)
 
    const auth = getAuth();
    console.log("GOT AUTH")
    console.log(auth)
    getRedirectResult(auth).then((result) => {
        console.log("Redirect result")
        console.log(result)
    }
    ).catch((error) => {
        console.log("Redirect error")
        console.log(error)
    }
    );
 
    await signInWithCredential(auth, credential).then((userCredential) => {
        navigateToNewPage('/company');
        //window.location.href = "/company";
    }).catch((error) => {
        console.error("Klarte ikke å logge inn, problem med google innlogging.")
        console.log(error);
    }
    );
    
    //await FirebaseAuthentication.linkWithGoogle();
    /*console.log("Login done")
    console.log(auth.currentUser)
    console.log(auth)
    setUserContext(old => {
        return { user: auth.currentUser, companyUser: old.companyUser }
    })
    console.log("Navigating to new page because of login")
    navigateToNewPage('/company');
};*/

export async function getAuthUser() {
    console.log("getAuthUser")
    try {
        /*const isAuthenticated = await checkIfAuthenticated();
        if (!isAuthenticated) {
            console.log('User is not authenticated');
            return null;
        }
 
        const user = await getCurrentUserWithRetry(5); // 5 retries for example*/
        console.log("getAuthUser")
        const user = await getAuth().currentUser
        //const user2 = await FirebaseAuthentication.getCurrentUser();
        console.log("user1")
        console.log(user)
        console.log("user2")

        console.log(user)

        if (user) {
            const { displayName, photoUrl, uid, email } = user;
            return { displayName, photoUrl, uid, email };
        } else {
            console.log("Failed to retrieve user.");
            return null;
        }

    } catch (error) {
        console.error("Error getting user:", error);
        return null;
    }
}

async function getCurrentUserWithRetry(retries) {
    if (retries <= 0) {
        throw new Error('Max retries reached');
    }

    try {
        //const result = await FirebaseAuthentication.getCurrentUser();
        const result = JSON.parse(localStorage.getItem(`${companyId()}User`));
        const user = auth.currentUser;
        const user2 = getAuth().currentUser;
        const idToken = localStorage.getItem("idToken");
        if (userToDist) {
            console.log("UserToDist")

            localStorage.setItem('userId', userToDist.uid);
            return userToDist;
        }

        if (result && result.user) {
            localStorage.setItem('userId', result.user.uid);
            return result.user;
        } else {
            // This will log any unexpected return from the getCurrentUser call
            console.warn("Unexpected result from FirebaseAuthentication.getCurrentUser:", JSON.stringify(result, null, 2));

            console.error(result)
            console.warn("Retries left:", retries);
        }
    } catch (error) {
        console.warn("Error in FirebaseAuthentication.getCurrentUser:", error);
    }

    // Wait for 1 second and retry
    await new Promise(resolve => setTimeout(resolve, 1000));
    return getCurrentUserWithRetry(retries - 1);
}



export async function getAuthUserId() {
    let userId = localStorage.getItem('userId');
    console.log("User ID Storage: " + userId);


    /* if (!userId || userId === "undefined") {
         console.log("No Result in User ID Storage");
         const user = await getAuthUser();
 
         if (user && user.uid) {
             console.log("User ID: " + user.uid);
             localStorage.setItem('userId', user.uid);
             return user.uid;
         } else {
             console.error("Failed to retrieve user from auth service.");
             return null; // or handle this case appropriately
         }
     }*/

    if (!userId || userId === "undefined") return null;
    console.log("User ID: " + userId);
    return userId;
}


export async function getAuthUserName() {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result?.user?.displayName;
}

export async function getAuthUserImage() {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result.user.photoURL;
}

export function createId() {
    return uuidv4();
}



// copy all Utstyr to Kompanier/utstyr
export async function copyClientsToCompanies() {
    console.log("Copying clients to companies");
    const oldClients = await getDocs(collection(db, "Utstyr"));
    console.log(oldClients);
    oldClients.forEach((docDD) => {
        const data = docDD.data();
        console.log(data);
        const newDocRef = doc(db, "Kompanier", companyId(), "utstyr", data.id);
        setDoc(newDocRef, data);
    }
    );
}


export const createCompany = async (company, moduleSetting) => {
    const functions = getFunctions(app, 'europe-west2');
    const createCompany = httpsCallable(functions, 'createCompany');

    try {
        const result = await createCompany({ company: company, moduleSetting: moduleSetting });
        console.info(result.data.message);
        return result.data;
    } catch (error) {
        console.error('Error calling createCompany:', error);
        throw error;
    }
}

export const updateCompany = async (company) => {
    const functions = getFunctions(app, 'europe-west2');
    const createCompany = httpsCallable(functions, 'updateCompany');

    try {
        const result = await createCompany({ company: company });
        console.info(result.data.message);
        return result.data;
    } catch (error) {
        console.error('Error calling createCompany:', error);
        throw error;
    }
}

export const saveFCMTokenToUser = async (token) => {
    // Put it into /users/{userId}/fcmTokens/{token}
    console.error("got token")
    const userId = store.getState().user?.user.uid || await getAuthUserId();
    console.error(userId)
    console.error(token)
    const userDocRef = collection(db, 'users', userId, 'fcmTokens');
    await addDoc(userDocRef, { token: token.token });
}


export async function handleReceivedNotification(notification, userContext) {
    return
    /*
    console.warn("handleReceivedNotification")
    console.warn(JSON.stringify(notification))
    const payload = notification.notification
    const KompaniId = payload.data.companyId;
    const userToken = payload.token;
    //db, "Kompanier", companyId(), "tid", userId, "timer", time.id
    const docRef = doc(db, "Kompanier", KompaniId, "tid", "timeliste", "liste", payload.data.timeListId);

    const pos = await getCurrentPosition()
    console.log("pos", JSON.stringify(pos))

    const timeList = await getDoc(docRef);
    const timeListData = timeList.data();
    console.log("timeListData", JSON.stringify(timeListData))

    let tilleg = await getCompanyTimeSettingsById(KompaniId);
    console.log("tilleg", JSON.stringify(tilleg))

    if (!tilleg) tilleg = [{
        id: createId(),
        name: "Standard",
        tilleg: 0,
        active: true,
    }];

    const timeStartObject = {
        id: createId(),
        startDate: timeListData.startDate,
        endDate: null,
        title: "",
        pause: false,
        tilleg: tilleg[0],
        stoppet: false,
        kunde: timeListData?.klient,
        prosjekt: timeListData?.prosjekt,
        bruker: JSON.parse(localStorage.getItem(companyId() + "User")),
    }

    const projectPos = { lng: timeListData?.place?.lng, lat: timeListData?.place?.lat };
    console.log("sending to handle")
    handleLocationComparison(timeStartObject, projectPos, pos, userToken)

    // Change the notification to be a new time object
*/
}


export async function handleLocationComparison(timeStartObject, projectPos, userLocation, userToken) {
    const radius = 300;  // define your radius

    if (isLocationWithinRadius(userLocation, projectPos, radius)) {
        modifyNotification(userToken, "Location Check", "Time has started");
        await uploadTimeToDb(timeStartObject)
    } else {
        modifyNotification(
            userToken,
            "Location Check",
            "It doesn't look like you're in the area, time hasn't automatically started. Do you want to start the time anyhow?"
        );
        //createFirebaseEntry(dataset.id, 'not-started');
    }
}

function modifyNotification(token, title, body) {
    LocalNotifications.schedule({
        notifications: [
            {
                title: title,
                body: body,
                id: randomId(),
                schedule: { at: new Date(Date.now() + 1000 * 2) },
                sound: null,
                attachments: null,
                actionTypeId: "",
                extra: null,
                smallIcon: "res://icon",
                iconColor: "#0000FF",
            },
        ],
    });
}

function isLocationWithinRadius(userLocation, datasetLocation, radius) {
    const distance = getDistance(userLocation, datasetLocation);
    return distance <= radius;
}




// Settings
export function SaveFirmSettings(settings, saveToString) {
    console.log("SaveFirmSettings")
    console.log(settings)
    setDoc(doc(db, "Kompanier", companyId(), "settings", saveToString), settings);
}

export async function getFirmSettings(type, onUpdate) {
    console.log("getFirmSettings")
    if (!type) return null;

    if (!companyId()) return null;


    // Get document
    const docRef = doc(db, "Kompanier", companyId(), "settings", type);
    const docSnap = await getDoc(docRef);

    //listen for updates

    if (typeof onUpdate === "function") {
        const unsubscribe = onSnapshot(docRef, (doc) => {
            onUpdate(doc.data());
        });
    }

    return docSnap.data();
}

export async function updateUser(user, prevUser) {
    // This needs to change with Context to get the proper user.
    console.log("updateUser")
    console.log(user)
    let tempUser = { ...user }
    console.log(prevUser)
    console.log(user.photoUrl)
    if (user.photoUrl !== prevUser.photoUrl) tempUser.photoUrl = await handleUpload(user.photoUrl, "bruker-bilder", createId() + '-small.png')
    console.log(user)
    console.log(tempUser)

    await updateDoc(doc(db, "users", user.uid), tempUser);
    localStorage.setItem("user", JSON.stringify(tempUser));
    return tempUser;
}

export async function getUser(userId) {
    // This needs to change with Context to get the proper user.
    console.log("getUser")
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
}

export async function checkIfUserIsAdmin() {
    // This needs to change with Context to get the proper user.
    try {
        const authUserID = store.getState().user?.user.uid || await getAuthUserId();
        console.log(authUserID)
        const docRef = doc(db, "admins", authUserID);
        const docSnap = await getDoc(docRef);
        console.log(docSnap.data())
        if (docSnap.data() !== undefined) return true;
        return false;
    } catch (error) {
        console.log(error)
        return false;
    }
}