





import React, { createContext, useState } from 'react';
import '../css/Loadingbar.css';

export const UploadingContext = createContext();

export default function UploadingContextProvider({ children }) {
    const [uploadBar, setUploadBar] = useState({
        show: false,
        progress: 0,
        message: "",
        steps: 0
    });

    const startUploadBar = (steps, message) => {
        setUploadBar({
            show: true,
            progress: 0,
            message: message,
            steps: steps
        })
    }

    const countUp = (message = "") => {
        setUploadBar(old => {
            return {
                ...old,
                message: message,
                progress: old.progress + 1
            }
        })
    }

    const endUploadBar = () => {
        setUploadBar(old => {
            return {
                ...old,
                progress: old.steps
            }
        })

        setTimeout(() => {
            setUploadBar(old => {
                return {
                    ...old,
                    show: false
                }
            })
        }, 500)
    }


    return (
        <UploadingContext.Provider value={{ startUploadBar, countUp, endUploadBar }}>
            {children}
            {uploadBar.show &&
                <div className='uploading-container column'>
                    <p>{uploadBar.message}</p>
                    <div className='loading-bar relative'>
                        <div className='loading-bar-inner' style={{ width: `${uploadBar.progress / uploadBar.steps * 100}%` }}></div>
                    </div>
                </div>
            }
        </UploadingContext.Provider>
    )
}

