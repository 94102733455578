import { useEffect, useState } from "react";
import { getUtstyr } from "../../firebase";
import { SearchWithPopup } from "./searchWithPopup";

export default function UtstyrSearchWithPopup({ onChange, value }) {
    const [utstyr, setUtstyr] = useState([]);
    const [chosenUtstyr, setChosenUtstyr] = useState(value || []);

    useEffect(() => {
        const onUpdate = (snapshot) => {
            const utstyr = snapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                }
            })
            setUtstyr(utstyr);
        }

        getUtstyr(onUpdate);
    }, []);

    useEffect(() => {
        onChange(chosenUtstyr)
    }, [chosenUtstyr])

    return (
        <SearchWithPopup quantity={true} items={utstyr} selected={
            chosenUtstyr.map(item => {
                return {
                    ...item,
                    quantity: item.quantity,
                    navn: item.navn + " (" + item.quantity + ")",
                }
            })
        } onChoise={
            (item) => {
                console.log(item)
                if (chosenUtstyr.filter(utstyr => utstyr.id === item.id).length > 0) {
                    const newUtstyr = [...chosenUtstyr];
                    chosenUtstyr.find(utstyr => utstyr.id === item.id).quantity += item.quantity;
                    return setChosenUtstyr(newUtstyr)
                }
                setChosenUtstyr([...chosenUtstyr, item])
            }
        } 
        onRemove={ (item) => {
            setChosenUtstyr(chosenUtstyr.filter(utstyr => utstyr.id !== item.id))
        }}
        />
    )
}