import { useHistory, useLocation } from "react-router";
import TimeCard, { SimplifiedTimeCardWeek } from "../../components/timetracking/timeCards";

import { useContext, useEffect, useRef, useState } from "react";
import { createId, getClientById, getClients, getClientsAndProjectsInOne, getCompanyTimeSettings, getProjectsByCompanyId, getRecordedTimeFromDB, getRecordedTimeFromDBOfCurrentDay, getRecordedTimeOfCurrentDay, getRecordedTimeOfMonth, uploadTimeToDb } from "../../firebase";
import { IonContent, IonModal } from "@ionic/react";
import TimeChooser from "../../components/timetracking/timeChooser";

//import { ReactComponent as SettingsIcon } from '../assets/icons/mdi_settings.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';

import CustomDropdown from "../../components/miniComponents/customDropdown";
import LoadingWhileEmpty from "../../components/miniComponents/loadingWhileEmpty";
import { UserContext } from "../../App";

import { ReactComponent as PlusIcon } from '../../assets/icons/material-symbols_add.svg';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TimePickerCarousel from "../../components/miniComponents/calendarInput";

import { Doughnut } from 'react-chartjs-2';

import { Chart, ArcElement, DoughnutController, Tooltip, Legend } from 'chart.js';
import { createDateOutOfFirebaseTimestamp, getDayName, getMonth } from "./timeføring";
import { useTranslation } from "react-i18next";
import { calculateHoursOfTimes } from "./timeOverview";
import TimetrackingDoughnut from "./utils/doughnut";
import { useSelector } from "react-redux";
import { getMonthId, getWeekId } from "./utils/utils";
import useCollectionSubscriptionRS from "../../components/utils/hooks/RSfirebaseCollectionSubscriber";
import { addTime, removeTime, setTimes, updateTime } from "../../stores/timeSlice";
import TrackedTimeCard from "../../components/timetracking/tracked-time-card";

// Register the components
Chart.register(ArcElement, DoughnutController, Tooltip, Legend);


const filterOptions = createFilterOptions({
    ignoreCase: true,
});


const information = {
    id: createId(),
    //startDate: new Date(),
    //endDate: new Date(),
    title: "",
    pause: false,
    tilleg: {
        id: createId(),
        name: "Normal",
        prosent: 0,
    },

}

export default function FørteTimer(props) {
    const { t } = useTranslation();
    const [months, setMonths] = useState(3);
    const history = useHistory();
    const user = useSelector(state => state.user.user);

    const monthSel = useSelector(state => state.time.months?.byId?.[getMonthId(new Date())]);
    const monthsSel = useSelector(state => state.time.months);
    const timesInMonth = useSelector(state => monthSel?.weeks.map(week => state.time?.weeks?.byId?.[week]?.times).flat());
    const { previousRecordings, isLoading } = useCollectionSubscriptionRS({
        selector: (state) => state.time.allTimes.allIds,
        path: `tid/${user.uid}/timer`,
        setFunction: setTimes,
        updateFunction: updateTime,
        addFunction: addTime,
        removeFunction: removeTime,
        key: "previousRecordings",
        payload: {},
        idOnly: true,
        returnItemsByIdSelector: (state) => state.time.allTimes.byId,
        // Only the once created by the user
        filters: [
            { field: 'startDate', operator: '>', value: new Date(new Date().getTime() - (24 * 60 * 60 * 1000 * 31 * 3)) }
        ]
    });

    const monthsInYear = Array.from({ length: months }, (v, i) => i + 1).map((month, index) => {
        const date = new Date();
        date.setMonth(date.getMonth() - index);
        //date.setMonth(index);
        return { date: date, month: date.getMonth() };
    });
    const sortedByTodaysDate = monthsInYear.sort((a, b) => a.date - b.date);

    const isValid = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - months);
        if (date.getFullYear() < 2023) return false;
        return true;
    }

    return (
        <div className="column content-ny padding-bottom-for-header">
            {/*<button className="btn btn-primary bottom-right-header-button"
                onClick={() => navigateToNewPage("/timeføring/ny")}
    >Ny Manuell Tid <PlusIcon /></button>*/}

            <LoadingWhileEmpty>
                {sortedByTodaysDate.reverse().map((month, index) => {
                    if (month.date > new Date()) return null;
                    return (<TrackedTimeCard key={index} month={month} />)
                })}
                {isValid() ?
                    <button className="" onClick={() => {
                        setMonths(old => months + 3)
                    }}>{t("Load more", "Se flere")}</button>
                    : <h2>{t("No more months to show", "Ingen flere måneder å vise")}</h2>}
            </LoadingWhileEmpty>
        </div>
    );
}

export function getWeekStartDateForWeekNumber(weekNumber, year) {
    // Start with the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const dayOfWeek = firstDayOfYear.getDay();

    // Adjust to the first Thursday of the year
    const daysToAdd = dayOfWeek <= 4 ? 4 - dayOfWeek : 11 - dayOfWeek;
    firstDayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd);

    // Calculate the start date of the given week number
    const startDate = new Date(firstDayOfYear);
    startDate.setDate(firstDayOfYear.getDate() + (weekNumber - 1) * 7);

    // Adjust to get the Monday of the week
    const day = startDate.getDay();
    startDate.setDate(startDate.getDate() - (day === 0 ? 6 : day - 1));

    return startDate;
}

export async function getAllTimesFromMonth(date, onUpdate) {
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const createWeeksInMonth = (monthStart, monthEnd, times) => {
        const weeksInMonth = generateWeeksInRange(monthStart, monthEnd).map(weekNumber => {
            const weekStartDate = getWeekStartDateForWeekNumber(weekNumber, date.getFullYear());
            const weekEndDate = new Date(weekStartDate);
            weekEndDate.setDate(weekEndDate.getDate() + 6);

            const weekTimes = times.filter(time => {
                const timeDate = createDateOutOfFirebaseTimestamp(time.startDate);
                return getNorwegianWeekNumber(timeDate) === weekNumber;
            });

            return {
                weekNumberYear: date.getFullYear(),
                weekNumber: weekNumber,
                times: weekTimes,
                startDate: weekStartDate,
                endDate: weekEndDate
            };
        }).reverse();
        return weeksInMonth;
    };

    const updateFunction = (times) => {
        onUpdate({ date: date, weeks: createWeeksInMonth(monthStart, monthEnd, times), listOfTimes: times })
    }

    const times = await getRecordedTimeOfMonth(date, onUpdate ? updateFunction : false);
    const weeks = createWeeksInMonth(monthStart, monthEnd, times);
    return { date: date, weeks: weeks, listOfTimes: times };
};

/*const DATA_COUNT = 5;
const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};
 
const data = {
  labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [{count: DATA_COUNT, min: 0, max: 100}],
      backgroundColor: Object.values(Utils.CHART_COLORS),
    }
  ]
};*/

export function getAllHoursOfMonth(month) {
    //console.log(month);
    const times = month.listOfTimes;
    let totalSeconds = 0;
    times.forEach(time => {
        if (!time.endDate || !time.startDate) return;
        const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
        const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
        totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
    });

    let totalHours = totalSeconds / 3600; // Convert total seconds to hours

    // If total hours are 100 or more, round to nearest whole number
    // Otherwise, round to nearest quarter hour and replace dot with comma for decimals
    return totalHours >= 100 ? Math.round(totalHours).toString() : (Math.floor(totalHours * 2) / 2).toString().replace('.', ',');
}



export function groupTimesByWeeks(times, startDate, endDate) {
    let weekNumbers = generateWeeksInRange(startDate, endDate);
    let weeks = weekNumbers.map(weekNumber => ({ weekNumber, times: [] }));

    times.forEach(time => {
        const date = createDateOutOfFirebaseTimestamp(time.startDate);
        const weekNumber = getNorwegianWeekNumber(date);

        let week = weeks.find(w => w.weekNumber === weekNumber);
        if (week) {
            week.times.push(time);
        }
    });

    return weeks;
}

export function generateWeeksInRange(startDate, endDate) {
    let weeks = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const weekNumber = getNorwegianWeekNumber(currentDate);
        const weekId = getWeekId(currentDate);

        // Get the start and end of the current week
        let startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1); // Monday start
        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday end

        // Calculate how many days in this week are within the range
        let overlapStart = startOfWeek < startDate ? startDate : startOfWeek;
        let overlapEnd = endOfWeek > endDate ? endDate : endOfWeek;
        let daysInRange = (overlapEnd - overlapStart) / (1000 * 60 * 60 * 24) + 1;

        // Only include the week if the majority of the days are in the range
        if (daysInRange >= 4 && !weeks.some(week => week.id === weekId)) { // Majority is 4 or more days
            weeks.push({ weekNumber, id: weekId, date: new Date(currentDate) });
            //console.log(`Adding week ${weekNumber} (ID: ${weekId}) for date: ${currentDate.toDateString()}`);
        } else {
            //console.log(`Skipping week ${weekNumber} (ID: ${weekId}) for date: ${currentDate.toDateString()}`);
        }

        // Move to the next Monday
        currentDate.setDate(currentDate.getDate() + (8 - currentDate.getDay()));
    }

    return weeks;
}




function getWeeksMajorityMonth(date) {
    const weekStart = getWeekStartDate(date);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6); // Get the end date (Sunday) of the week

    // Check if the month changes within the week
    if (weekStart.getMonth() !== weekEnd.getMonth()) {
        // If the month changes, determine which month has the majority of days
        const midWeek = new Date(weekStart);
        midWeek.setDate(midWeek.getDate() + 3); // Get the middle day of the week

        return midWeek.getMonth(); // Return the month of the middle day
    }

    return weekStart.getMonth(); // If the month doesn't change, return the month of the week start
}




export function getNorwegianWeekNumber(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Set the date to the nearest Thursday: current date + 3 - current day number
    // Make Sunday's day number 7
    newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

    // Get the first day of the year
    const yearStart = new Date(newDate.getFullYear(), 0, 1);

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    // Return the week number
    return weekNo;
}

function getWeekStartDate(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Adjust to get the Monday of the week
    // If it's Sunday (0), set to -6, otherwise subtract the day number - 1
    const dayOfWeek = newDate.getDay();
    const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    newDate.setDate(newDate.getDate() + difference);

    return newDate;
}


export function getDayInMonth(date) {
    let dateCopy = createDateOutOfFirebaseTimestamp(date)
    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }
    if (!date) dateCopy = new Date()

    return dateCopy.getDate()
}


export function makeDateReadable(date, t) {
    const now = new Date();
    const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Convert `date` to a Date object if it is a Unix timestamp.
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    // If it is under a week old, return day by name.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 7) {
        return getDayName(date, t);
    }

    // If it is within the current month, return day and month.
    if (date >= firstDayOfCurrentMonth) {
        return date.getDate() + ". " + (date.getMonth() + 1);
    }

    // If it is under a year old, return month and day.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 365) {
        return date.getDate() + ". " + (date.getMonth() + 1);
    }

    // If it is over a year old, return year, month, and day.
    return date.getDate() + ". " + (date.getMonth() + 1) + " " + date.getFullYear();
}




export const TimeChoiseModal = ({
    open, start, end, tilleg, tillegOptions,
    onDone, onClose,
    title,
    kunde, kunder,
    project, projects,
}) => {
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)
    const [tillegToUpdate, setTillegg] = useState(tilleg)

    //console.log(tillegOptions)
    //console.log(tilleg)
    //console.log(tillegToUpdate)


    useEffect(() => {
        setStartDate(start)
        setEndDate(end)

    }, [start, end])

    //console.log(startDate, endDate)
    //console.log(start, end)

    return (
        <IonModal isOpen={open} cssClass='my-custom-class' onDidDismiss={onClose}>
            <IonContent>
                <div className="content-ny">
                    <div className="row flexApart">
                        <button className="btn btn-primary" onClick={() => {
                            onClose()
                        }}>Tilbake</button>

                        <button className="btn btn-primary" onClick={() => {
                            onDone(startDate, endDate, tillegToUpdate)
                        }}>Ferdig</button>
                    </div>
                    {tillegOptions &&
                        <div className="column flexApart">
                            <h2>Tillegg</h2>

                            <div className="typeAvTillegg">
                                <CustomDropdown options={tillegOptions} selected={tillegToUpdate} onChange={(e) => setTillegg(e)} />
                            </div>
                        </div>
                    }
                    <h2>Fra</h2>
                    <TimeChooser date={startDate} onChange={(e) => setStartDate(e)} />
                    <h2>Til</h2>
                    <TimeChooser date={endDate} onChange={(e) => setEndDate(e)} />

                </div>
            </IonContent>
        </IonModal>
    )
}
