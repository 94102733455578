import { useEffect } from "react";





export function DebounceItem({ id, functionToCall, params, removeSelf }) {

    useEffect(() => {
        const save = async (e) => {
            console.log('save', e.detail.id, id);
            if (e.detail.id === id) {
                console.log(e.detail.params)
                console.log('Calling function');
                await functionToCall(e.detail.params);
                removeSelf(id);
            }
        }
        window.addEventListener('saveCurrentEdits', save);

        return () => {
            window.removeEventListener('saveCurrentEdits', save);
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(async () => {
            await functionToCall(params);
        }, 1000);
        return () => clearTimeout(timer);
    }, [JSON.stringify(params)])

    return (<></>)
}