




export function checkRolesForPermission(roles, permission) {
    if (!roles || !permission) return false;

    // If roles is an array
    if (Array.isArray(roles)) {
        // Check if "admin" is present in roles
        if (roles.includes("admin")) return true;

        // If permission is an array, check each permission
        if (Array.isArray(permission)) {
            return permission.some(perm => roles.includes(perm));
        }

        // Otherwise, check the single permission
        return roles.includes(permission);
    }

    // If roles is an object
    if (typeof roles === "object") {
        // Check if the "admin" role is true
        if (roles.admin === true) return true;

        // If permission is an array, check each permission
        if (Array.isArray(permission)) {
            return permission.some(perm => roles[perm] === true);
        }

        // Otherwise, check the single permission
        return roles[permission] === true;
    }

    return false; // If roles is neither an array nor an object, return false
}



export function combineAllToCheckPermissions(roles) {
    // combine all roles, where if the key is true, it should override false keys, combining into one array with all permissions
    let combinedRoles = [];
    roles.forEach(role => {
        //if (!combinedRoles[role]) combinedRoles.push(role);
        Object.keys(role).forEach(key => {
            //If it doesn't already exist, add it if it is true
            //console.log(key)
            if (role[key] === true && !combinedRoles[key]) combinedRoles.push(key);
        })
    })
    return combinedRoles;
}